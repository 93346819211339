import { SearchOutlined } from '@ant-design/icons';
import { contentProviderService } from 'account/services/contentProviderService';
import { Button, DatePicker, Input, Select } from 'antd';
import * as Components from 'components';
import { RevenueShareInterface } from 'content/type';
import { contractService } from 'contract/services/contractService';
import dayjs from 'dayjs';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import * as Component from '../../../../shared/components';
import { revenueService } from '../../services';
import { getRevenueShareColumns } from './RevenueShareColumns';
export const Revenue: React.FC = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [contentProviderList, setContentProviderList] = useState<{ id: number; title: string }[]>(
    []
  );
  const [contractList, setContractList] = useState<
    {
      id: number;
      title: string;
      licensor: number;
    }[]
  >([]);
  const [title, setTitle] = useState<string>();
  const [selectStartYearMonth, setSelectStartYearMonth] = useState<Moment>(moment('2024-01'));
  const [selectEndYearMonth, setSelectEndYearMonth] = useState<Moment>(moment('2024-12'));
  const [selectContractId, setSelectContractId] = useState<number>();
  const [selectContentProvider, setSelectContentProvider] = useState<string>();
  const [selectGroupBy, setSelectGroupBy] = useState<string>('season');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [searchParams, setSearchParams] = useState({
    title: '',
    startYearMonth: undefined as Moment | undefined,
    endYearMonth: undefined as Moment | undefined,
    contractId: undefined as number | undefined,
    contentProvider: undefined as string | undefined,
    groupBy: 'season' as string | undefined,
  });
  const columns = getRevenueShareColumns(searchParams.groupBy || 'season');

  const fetchRevenue = useCallback(
    async (
      page: number,
      pageSize: number
    ): Promise<{ data: RevenueShareInterface[]; count: number }> => {
      const queryParams = {
        page: page.toString(),
        page_size: pageSize.toString(),
        title: searchParams.title ?? '',
        start_yearmon: searchParams.startYearMonth
          ? searchParams.startYearMonth.format('YYYY-MM')
          : '',
        end_yearmon: searchParams.endYearMonth ? searchParams.endYearMonth.format('YYYY-MM') : '',
        contract_id: searchParams.contractId?.toString() ?? '',
        content_provider_id: searchParams.contentProvider ?? '',
        group_by: searchParams.groupBy ?? '',
      };
      const revenueData = await revenueService.fetchShare(queryParams);
      return { data: revenueData.results, count: revenueData.count };
    },
    [searchParams]
  );

  const fetchContentProvider = async (): Promise<void> => {
    const contentProviderData = await contentProviderService.fetchContentProvider();
    setContentProviderList([{ id: 0, title: 'All' }, ...contentProviderData]);
  };

  const fetchContract = async (): Promise<void> => {
    const queryParams = {
      page_size: '100',
    };
    const contractData = await contractService.fetch(queryParams);
    setContractList([{ id: 0, title: 'All', licensor: 0 }, ...contractData.results]);
  };

  const handleOnClickFilterReset = (): void => {
    setTitle('');
    setSelectStartYearMonth(moment('2024-01'));
    setSelectEndYearMonth(moment('2024-12'));
    setSelectContractId(undefined);
    setSelectContentProvider(undefined);
    setSelectGroupBy('season');
    setSearchParams({
      title: '',
      startYearMonth: moment('2024-01'),
      endYearMonth: moment('2024-12'),
      contractId: undefined,
      contentProvider: undefined,
      groupBy: 'season',
    });
  };

  const handleApplyFilters = (): void => {
    setSearchParams(prev => ({
      ...prev,
      title: title ?? '',
      startYearMonth: selectStartYearMonth,
      endYearMonth: selectEndYearMonth,
      contractId: selectContractId === 0 ? undefined : selectContractId,
      contentProvider:
        selectContentProvider && selectContentProvider !== 'All'
          ? selectContentProvider
          : undefined,
      groupBy: selectGroupBy,
    }));
    setRefreshCounter(prev => prev + 1);
  };

  const handleContentProviderChange = (value: string): void => {
    setSelectContentProvider(value);
  };

  const getFilteredContracts = useCallback((): {
    id: number;
    title: string;
    licensor: number;
  }[] => {
    if (!selectContentProvider) {
      return contractList;
    }
    return [
      { id: 0, title: 'All', licensor: 0 },
      ...contractList.filter(contract => contract.licensor === Number(selectContentProvider)),
    ];
  }, [contractList, selectContentProvider]);

  useEffect(() => {
    if (contentProviderList.length === 0) {
      fetchContentProvider().then();
    }

    if (contractList.length === 0) {
      fetchContract().then();
    }
  }, []);

  return (
    <Component.BasePageComponent>
      <div style={{ marginBottom: '1rem' }}>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          style={{
            marginLeft: '10px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #d9d9d9',
            marginBottom: '10px',
          }}
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </Button>
        {showFilters && (
          <div
            style={{
              border: '1px solid #d9d9d9',
              padding: '10px',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            }}
          >
            <div>
              Title :
              <Input
                style={{ width: 200, margin: '5px' }}
                value={title}
                onChange={e => setTitle(e.target.value)}
              />{' '}
            </div>
            <div>
              Content Provider :
              <Select
                style={{ width: 200, margin: '5px' }}
                placeholder="All"
                value={selectContentProvider}
                onChange={handleContentProviderChange}
                options={contentProviderList.map(item => ({
                  value: item.id,
                  label: item.title,
                }))}
              />
            </div>
            <div>
              Contract :
              <Select
                style={{ width: 200, margin: '5px' }}
                value={selectContractId}
                placeholder="All"
                onChange={value => setSelectContractId(Number(value))}
                options={getFilteredContracts().map(item => ({
                  value: item.id,
                  label: item.title,
                }))}
              />
            </div>
            <div>
              Group by :
              <Select
                style={{ width: 200, margin: '5px' }}
                placeholder="Group by"
                value={selectGroupBy}
                onChange={value => setSelectGroupBy(value)}
                options={[
                  {
                    value: 'content',
                    label: 'Content',
                  },
                  {
                    value: 'season',
                    label: 'Season',
                  },
                ]}
              />
            </div>
            <div>
              Period :
              <DatePicker.MonthPicker
                style={{ margin: '5px' }}
                placeholder="Start Year/Month"
                value={dayjs(selectStartYearMonth.format('YYYY-MM'))}
                onChange={date => setSelectStartYearMonth(moment(date?.toDate()))}
                format="YYYY-MM"
              />
              ~
              <DatePicker.MonthPicker
                placeholder="End Year/Month"
                value={dayjs(selectEndYearMonth.format('YYYY-MM'))}
                style={{ margin: '5px' }}
                onChange={date => setSelectEndYearMonth(moment(date?.toDate()))}
                format="YYYY-MM"
              />
            </div>
            <Button style={{ margin: '5px' }} onClick={handleApplyFilters}>
              Apply filters
            </Button>
            <Button style={{ margin: '5px' }} onClick={() => handleOnClickFilterReset()}>
              Clear filters
            </Button>
          </div>
        )}
      </div>

      <Components.TableComponent
        key={refreshCounter}
        tableName="Revenue Share"
        columns={columns}
        isDisableIdColumn={true}
        dataFetch={fetchRevenue}
      />
    </Component.BasePageComponent>
  );
};

export default Revenue;
