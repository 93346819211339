import { TablePaginationConfig } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UseTableReturn } from 'type/tableType';

export const useTable = <T>({
  dataSource,
  setCurrent,
  setPageSize,
  setSelectedList,
  setSelectAll,
}: {
  dataSource: T[];
  setCurrent: (current: number) => void;
  setPageSize: (pageSize: number) => void;
  setSelectedList: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
}): UseTableReturn => {
  const onPageChange = async (pagination: TablePaginationConfig): Promise<void> => {
    setCurrent(pagination.current || 1);
    setPageSize(pagination.pageSize || 10);
  };

  const handleSelectAll = (e: CheckboxChangeEvent): void => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedList(dataSource.map(item => (item as { id: number }).id));
      setSelectAll(true);
    } else {
      setSelectedList([]);
      setSelectAll(false);
    }
  };

  const handleSelect = (id: number): void => {
    setSelectedList(prevSelectedList =>
      prevSelectedList.includes(id)
        ? prevSelectedList.filter(existingId => existingId !== id)
        : [...prevSelectedList, id]
    );
  };

  return {
    onPageChange,
    handleSelectAll,
    handleSelect,
  };
};
