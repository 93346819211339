import { Flex, Form, message, Switch, TableColumnsType } from 'antd';
import * as Component from 'components';
import {
  TransferDataHandlerInterface,
  TransferDataSourceInterface,
} from 'components/transfer/Transfer';
import { seasonService, seriesSeasonItemService } from 'content/service';
import { SeasonInterface } from 'content/type';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Season.css';

interface SeasonProps {
  seriesId: number;
}

const Season: React.FC<SeasonProps> = ({ seriesId }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [] = Form.useForm();

  const handleOnClickTitle = (id: number): void => {
    navigate(`/season/edit/${id}`);
  };

  const columns = [
    {
      title: 'POSTER',
      dataIndex: 'poster',
      key: 'poster',
      width: '5%',
      render: (_text: string, record: SeasonInterface): JSX.Element => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ marginLeft: '10px', width: '100%', height: 'auto' }}
            src={record.poster?.file}
            alt=""
          />
        </div>
      ),
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      render: (text: string, record: SeasonInterface): JSX.Element => (
        <div className="season-title" onClick={() => handleOnClickTitle(record.id || 0)}>
          <div
            className="title-season"
            style={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </div>
        </div>
      ),
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'created (UTC)',
      dataIndex: 'created',
      key: 'created',
      render: (text: string, _: SeasonInterface): JSX.Element => (
        <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
      ),
    },
  ];

  const fetchSeason = useCallback(
    async (page: number, pageSize: number): Promise<{ data: SeasonInterface[]; count: number }> => {
      const contentList = await seasonService.fetch({
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchTerm,
        series_id: seriesId.toString(),
      });
      return { data: contentList.results, count: contentList.count };
    },
    [searchTerm]
  );

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  const transferColumns: TableColumnsType<TransferDataSourceInterface> = [
    {
      dataIndex: 'id',
      title: 'ID',
      render: (id: number) => (
        <span>
          <Link to={`/video/detail/${id}`} target="_blank">
            {id}
          </Link>
        </span>
      ),
    },
    {
      dataIndex: 'name',
      title: 'Name',
    },
  ];

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (
      query: Record<string, string>
    ): Promise<{ data: TransferDataSourceInterface[]; count: number }> => {
      const extendedQuery = { ...query };
      const seasons = await seasonService.fetch(extendedQuery);
      const transferDataSource = seasons.results.map(season => ({
        id: season.id,
        key: season.id,
        name: season.title,
        disabled: false,
      }));
      return {
        data: transferDataSource,
        count: seasons.count,
      };
    },
    fetchTargetData: async (
      query: Record<string, string>
    ): Promise<{ data: TransferDataSourceInterface[]; count: number }> => {
      const extendedQuery = { ...query, series_id: seriesId.toString() };
      const seasons = await seasonService.fetch(extendedQuery);
      return {
        data: seasons.results.map(season => ({
          id: season.id,
          key: season.id,
          name: season.title,
          disabled: false,
        })),
        count: seasons.count,
      };
    },
    createWithParentId: async (itemIds: number[]): Promise<void> => {
      await seriesSeasonItemService.createItemsForParent(seriesId, itemIds);
      message.success('Successfully added to the season');
    },
    deleteWithParentId: async (itemIds: number[]): Promise<void> => {
      await seriesSeasonItemService.deleteItemsForParent(seriesId, itemIds);
      message.success('Successfully removed from the season');
    },
  };

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Switch
          checkedChildren="Edit Mode"
          unCheckedChildren="View Mode"
          onChange={(checked: boolean) => setEditMode(checked)}
          checked={editMode}
        />
      </div>
      {editMode ? (
        <>
          <Flex align="start" gap="middle" vertical>
            <Component.TransferComponent dataHandler={dataHandler} columns={transferColumns} />
          </Flex>
        </>
      ) : (
        <Component.TableComponent<SeasonInterface>
          key={refreshCounter}
          tableName="Series Seasons"
          searchTerm={searchTerm}
          dataFetch={fetchSeason}
          columns={columns}
          handleSearchTerm={setSearchTerm}
        />
      )}
    </>
  );
};

export default observer(Season);
