import axios from 'axios';
import { BASE_URL, REVENUE_API_PATH } from 'constants/api';
import { RevenueInterface, RevenueShareInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class RevenueService {
  constructor(private readonly apiService: ApiService<RevenueInterface>) {}

  public async fetch(queryParams?: Record<string, string>): Promise<FetchResult<RevenueInterface>> {
    return await this.apiService.fetch(queryParams);
  }

  public async fetchShare(
    queryParams?: Record<string, string>
  ): Promise<FetchResult<RevenueShareInterface>> {
    const response = await axios.get(`${BASE_URL}${REVENUE_API_PATH}share/`, {
      params: queryParams,
    });
    return response.data;
  }
}

export const revenueService = new RevenueService(new ApiService(REVENUE_API_PATH));
