import SidebarComponent from 'components/sidebar/Sidebar';
import { METADATA_SUB_PATHS } from 'constants/index';
import {
  ADMIN_MENU_ITEMS,
  CONTENT_MENU_ITEMS,
  CONTRACT_MENU_ITEMS,
  CP_MENU_KEY_LIST,
} from 'constants/menu';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
import { default as CustomBreadcrumb } from '../breadcrumb/Breadcrumb';
import './BasePage.css';
import PageHeader from './PageHeader';

export interface BaseProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useMenuFiltering = () => {
  const role = accountStore.getRole();

  const contentMenuList = useMemo(
    () =>
      CONTENT_MENU_ITEMS!.filter(item =>
        role === 'operator' ? CP_MENU_KEY_LIST.includes(item!.key as string) : true
      ),
    [role]
  );

  const contractMenuList = useMemo(
    () =>
      CONTRACT_MENU_ITEMS!.filter(item =>
        role === 'operator' ? CP_MENU_KEY_LIST.includes(item!.key as string) : true
      ),
    [role]
  );

  const adminMenuList = useMemo(
    () =>
      ADMIN_MENU_ITEMS!.filter(item =>
        role === 'admin' ? true : CP_MENU_KEY_LIST.includes(item!.key as string)
      ),
    [role]
  );

  return { contentMenuList, contractMenuList, adminMenuList };
};

const BasePage: React.FC<BaseProps> = observer(({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  const defaultOpenKeys = METADATA_SUB_PATHS.includes(currentPath) ? ['metadata'] : [];
  const breadcrumbs = CustomBreadcrumb();
  const breadcrumbItems = breadcrumbs.map((breadcrumb, index) => ({
    title: breadcrumb.title,
    key: index,
    href: breadcrumb.url,
  }));

  const { contentMenuList, contractMenuList, adminMenuList } = useMenuFiltering();

  return (
    <SidebarComponent
      contentMenuList={contentMenuList}
      contractMenuList={contractMenuList}
      currentPath={currentPath}
      defaultOpenKeys={defaultOpenKeys}
    >
      <PageHeader
        userName={accountStore.getAccount()?.username || ''}
        breadcrumbItems={breadcrumbItems}
        adminMenuList={adminMenuList}
      />
      <div className="base-page-component">{children}</div>
    </SidebarComponent>
  );
});

export default React.memo(BasePage);
