import axios from 'axios';
import { ADMIN_USER_API_PATH, BASE_URL, USER_API_PATH } from 'constants/api';
import { AccountInterface, UserProjectRoleInterface } from '../type/accountType';
import { ApiService } from './apiService';

class AccountService {
  constructor(private apiService: ApiService<AccountInterface>) {}

  public async fetch(): Promise<AccountInterface> {
    const response = await axios.get(`${BASE_URL}${USER_API_PATH}`);
    return response.data;
  }

  public async create(account: AccountInterface): Promise<AccountInterface> {
    return await this.apiService.create(account);
  }

  public async fetchById(id: number): Promise<AccountInterface> {
    return await this.apiService.fetchById(id);
  }

  public async update(id: number, account: AccountInterface): Promise<AccountInterface> {
    return await this.apiService.update(id, account);
  }

  public async delete(id: number): Promise<void> {
    await this.apiService.delete(id);
  }

  public async updatePassword(oldPassword: string, newPassword: string): Promise<AccountInterface> {
    const response = await axios.patch(`${BASE_URL}${USER_API_PATH}password/`, {
      old_password: oldPassword,
      new_password: newPassword,
    });
    return response.data;
  }

  public async getUserProjectRoles(id: number): Promise<UserProjectRoleInterface> {
    const response = await axios.get(`${BASE_URL}${ADMIN_USER_API_PATH}${id}`);
    return response.data;
  }
}

export const accountService = new AccountService(new ApiService(USER_API_PATH));
