import { contentProviderService } from 'account/services/contentProviderService';
import { FilterValue } from 'antd/es/table/interface';
import { RevenueInterface } from 'content/type';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  LabelProps,
  Legend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { revenueService } from 'src/domain/revenue/services/revenueService';
import { getRevenueBarChartHandler } from './RevenueBarChartHandler';

type RevenueBarChartProps = {
  filters: Record<string, FilterValue | null>;
  selectedMonth: string | null;
  selectedRow: string | null;
  setSelectedMonth: (month: string | null) => void;
};

export const RevenueBarChart: React.FC<RevenueBarChartProps> = ({
  selectedMonth,
  filters,
  selectedRow,
  setSelectedMonth,
}: RevenueBarChartProps) => {
  const [barChartData, setBarChartData] = useState<RevenueInterface[]>([]);
  const [filteredBarChartData, setFilteredBarChartData] = useState<RevenueInterface[]>([]);
  const [selectedLegend, setSelectedLegend] = useState<string | null>(null);
  const { handleLegendClick, handleBarClick } = getRevenueBarChartHandler({
    selectedMonth,
    selectedLegend,
    setSelectedMonth,
    setSelectedLegend,
  });

  const renderCustomLabel = ({ x, y, value, width }: LabelProps): React.ReactNode => {
    if (typeof x === 'undefined' || typeof y === 'undefined' || typeof value === 'undefined') {
      return null;
    }
    return (
      <Text
        x={Number(x) + Number(width) / 2} // x 위치를 막대의 중앙으로 조정
        y={Number(y) - 10} // y 위치를 막대 위로 약간 띄움
        fill="#007bff"
        textAnchor="middle"
        fontSize={14}
        fontWeight="bold"
      >
        {`${(Number(value) / 1000).toFixed(1)}K`}
      </Text>
    );
  };

  const getOpacity = (currentKey: string): number => {
    if (selectedLegend) {
      return selectedLegend === currentKey ? 1 : 0.3;
    }
    return 1;
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const groupBy = filters.group_by?.[0] === 'content_provider' ? 'content_provider' : 'yearmon';
      const [contentProvider, rowTitle, yearmon] = selectedRow?.split(':-') ?? [];
      const cpList = await contentProviderService.fetch();
      const cpId = cpList.results.find(cp => cp.title === contentProvider)?.id;
      const queryParam: Record<string, string> = {
        content_id: String(filters.content_id?.[0] ?? ''),
        title: rowTitle === 'null' ? '' : String(rowTitle ?? filters.title?.[0] ?? ''),
        start_yearmon:
          yearmon === 'null' ? '' : String(yearmon ?? filters.start_yearmon?.[0] ?? ''),
        end_yearmon: yearmon === 'null' ? '' : String(yearmon ?? filters.end_yearmon?.[0] ?? ''),
        content_provider_id: String(cpId ?? filters.content_provider_id?.[0] ?? ''),
        group_by: selectedRow ? 'yearmon' : String(groupBy),
        order_by: String(filters.order_by?.[0] ?? ''),
        order: String(filters.order?.[0] ?? ''),
        paging: String(filters.paging?.[0] ?? ''),
      };
      Object.keys(queryParam).forEach(key => {
        if (!queryParam[key]) {
          delete queryParam[key];
        }
      });
      const data = await revenueService.fetch(queryParam);
      setBarChartData(data.results);
      setFilteredBarChartData(data.results);
    };
    fetchData();
  }, [filters, selectedRow]);

  useEffect(() => {
    if (selectedMonth) {
      setFilteredBarChartData(barChartData.filter(item => item.yearmon === selectedMonth));
    } else {
      setFilteredBarChartData(barChartData);
    }
  }, [selectedMonth]);

  return (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={filteredBarChartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          onClick={e => e && e.activePayload && handleBarClick(e.activePayload[0].payload.yearmon)}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {selectedRow || filters.group_by?.[0] !== 'content_provider' ? (
            <XAxis
              dataKey="yearmon"
              tickFormatter={(value: string) => dayjs(value).format('YYYY-MM')}
            />
          ) : (
            <XAxis dataKey="content_provider" />
          )}
          <YAxis />
          <Tooltip />
          <Legend
            onClick={handleLegendClick}
            wrapperStyle={{
              cursor: 'pointer',
            }}
            onMouseEnter={e => {
              setSelectedLegend(e.dataKey as string);
            }}
            onMouseLeave={() => {
              setSelectedLegend(null);
            }}
            formatter={(value, entry: Payload) => (
              <span
                style={{
                  fontWeight: selectedMonth === entry.dataKey ? 'bold' : 'normal',
                  color: selectedMonth === entry.dataKey ? '#000' : '#666',
                }}
              >
                {value}
              </span>
            )}
          />
          <Bar dataKey="avod_rev" stackId="a" fill="#8884d8" opacity={getOpacity('avod_rev')} />
          <Bar dataKey="fast_rev" stackId="a" fill="#82ca9d" opacity={getOpacity('fast_rev')} />
          <Bar dataKey="media_rev" stackId="a" fill="#ffc658" opacity={getOpacity('media_rev')} />
          <Bar
            dataKey="product_ip_rev"
            stackId="a"
            fill="#ff8042"
            opacity={getOpacity('product_ip_rev')}
          />
          <Bar dataKey="ppv_rev" stackId="a" fill="#8dd1e1" opacity={getOpacity('ppv_rev')} />
          <Bar dataKey="svod_rev" stackId="a" fill="#a4de6c" opacity={getOpacity('svod_rev')} />
          <Bar
            dataKey="theatrical_rev"
            stackId="a"
            fill="#d0ed57"
            opacity={getOpacity('theatrical_rev')}
          >
            <LabelList dataKey="total_rev" content={renderCustomLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
