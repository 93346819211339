import { Button, Form, Input } from 'antd';
import { LoadingSpinnerComponent } from 'components';
import { playlistRuleHandlers } from 'content/page/playlist/handlers';
import { categoryService, playlistContentService } from 'content/service';
import { CategoryInterface } from 'content/type';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CategoryRule, SeasonRule, SeriesRule } from './components';

export const PlaylistRule: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<CategoryInterface[]>([]);
  const { handleSave, handleCategorySelect } = playlistRuleHandlers({
    form,
    playlistId: Number(id),
  });
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const fetchedRules = await playlistContentService.fetchRules(Number(id));
      const fetchedCategories = await categoryService.fetch();
      setCategories(fetchedCategories);
      setIsLoading(false);
      fetchedRules.forEach(rule => {
        form.setFieldValue(
          rule.value_type,
          rule.value.split(',').map(value => Number(value))
        );
      });
    };
    fetchData();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinnerComponent />
      ) : (
        <Form form={form}>
          <h3 style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <span>Recent</span>
            <span
              style={{
                flex: 1,
                borderBottom: '1px solid #666',
                marginLeft: '8px',
                height: '0.5em',
              }}
            />
          </h3>
          <div
            style={{
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px', // 라벨과 입력창 사이 간격
            }}
          >
            <Form.Item name="recent" noStyle>
              <Input style={{ width: '100px' }} />
            </Form.Item>
            <span style={{ fontSize: '16px' }}> days</span>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <CategoryRule
              handleCategorySelect={handleCategorySelect}
              categories={categories}
              form={form}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <SeriesRule form={form} />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <SeasonRule form={form} />
          </div>
          <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
            <Button
              type="primary"
              onClick={() => {
                handleSave();
                navigate('/playlists');
              }}
            >
              save
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default PlaylistRule;
