import { FormInstance } from 'antd';
import { TransferDataHandlerInterface } from 'components/transfer/Transfer';
import { playlistContentService } from 'content/service/playlistContentService';
import { seriesService } from 'content/service/seriesService';
import { SeriesInterface } from 'content/type';

interface SeriesRuleHandler {
  filterOption: (input: string, item: SeriesInterface) => boolean;
  dataHandler: TransferDataHandlerInterface;
}

const seriesRuleHandler = ({
  id,
  form,
  setRefreshKey,
}: {
  id: string;
  form: FormInstance;
  setRefreshKey: React.Dispatch<React.SetStateAction<number>>;
}): SeriesRuleHandler => {
  const filterOption = (input: string, item: SeriesInterface): boolean =>
    item.title?.includes(input);

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (query: Record<string, string>) => {
      const series = await seriesService.fetch(query);
      return {
        data: series.results.map((series: SeriesInterface) => ({
          ...series,
          key: series.id,
          name: series.title,
          disabled: false,
        })),
        count: series.count,
      };
    },
    fetchTargetData: async () => {
      const targetSeriesIds = [];
      const seriesFormData = form.getFieldValue('series');
      if (!seriesFormData || seriesFormData.length === 0) {
        const fetchedRules = await playlistContentService.fetchRules(Number(id));
        const selectedSeries = fetchedRules.filter(rule => rule.value_type === 'series');
        const selectedSeriesIds =
          selectedSeries.length > 0
            ? selectedSeries[0].value.split(',').map(value => Number(value))
            : [];
        targetSeriesIds.push(...selectedSeriesIds);
      } else {
        targetSeriesIds.push(...seriesFormData);
      }
      const series = await seriesService.fetchByIds(targetSeriesIds);
      return {
        data: series.map((series: SeriesInterface) => ({
          ...series,
          key: series.id,
          name: series.title,
          disabled: false,
        })),
        count: series.length,
      };
    },
    createWithParentId: async (ids: number[]) => {
      const seriesIds = form.getFieldValue('series');
      if (!seriesIds) {
        form.setFieldValue('series', ids);
      } else {
        const deduplicatedIds = [...new Set([...seriesIds, ...ids])];
        form.setFieldValue('series', deduplicatedIds);
      }
      setRefreshKey(prev => prev + 1);
    },
    deleteWithParentId: async (ids: number[]) => {
      const seriesIds = form.getFieldValue('series');
      const deduplicatedIds = seriesIds.filter((id: number) => !ids.includes(id));
      form.setFieldValue('series', deduplicatedIds);
      setRefreshKey(prev => prev + 1);
    },
  };

  return {
    filterOption,
    dataHandler,
  };
};

export default seriesRuleHandler;
