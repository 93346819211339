import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { TableColumnInterface } from 'components/table/Table';
import React from 'react';
import { PlaylistInterface } from '../../type/playlistType';
interface TableColumnProps {
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  onClickDeleteButton: (id: number) => void;
}

export const getPlaylistColumns = ({
  handleOnClickTitle,
  handleOnClickSetting,
  onClickDeleteButton,
}: TableColumnProps): TableColumnInterface<PlaylistInterface>[] => {
  return [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, record: PlaylistInterface) => (
        <a href="#" className="playlist-title" onClick={() => handleOnClickTitle(record.id)}>
          {text}
        </a>
      ),
    },
    {
      title: 'MRSS URL',
      dataIndex: 'mrss_url',
      key: 'mrss_url',
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'CREATED',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (_text: string, record: PlaylistInterface) => (
        <Space>
          <Button
            icon={<SettingOutlined />}
            onClick={() => handleOnClickSetting(record.id)}
            className="action-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => onClickDeleteButton(record.id)}
            className="action-btn delete-btn"
          />
        </Space>
      ),
    },
  ];
};
