import { Form, message } from 'antd';
import { playlistService } from 'content/service';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { PlaylistInterface } from '../../type/playlistType';
import * as PlaylistComponent from './components';

const PlaylistAdd: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddClick = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      const playlist: PlaylistInterface = {
        id: -1,
        title: values.title,
        mrss_url: values.mrss_url,
        description: values.description,
        active: values.active,
        playlist_items: [],
        created: values.created,
        modified: values.modified,
        type: values.type || 'manual',
      };

      await playlistService.create(playlist);
      setIsLoading(false);
      navigate('/playlists/');
    } catch (error) {
      message.error('Failed to add : ' + error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <Component.BasePageComponent>
          <PlaylistComponent.DetailComponent
            form={form}
            actionTitle="Add"
            handleOnClickButton={handleAddClick}
          />
        </Component.BasePageComponent>
      )}
    </>
  );
};

export default PlaylistAdd;
