import { Button, Space } from 'antd';
import React from 'react';
import { TableColumnInterface } from '../../../../shared/components/table/Table';
import { UserInterface } from '../../type/userType';

interface UserColumnsProps {
  onClickEditButton: (id: number) => void;
  onClickDeleteButton: (id: number) => void;
  contentProviderList: { id: number; title: string }[];
}

export const getUserColumns = ({
  onClickEditButton,
  onClickDeleteButton,
  contentProviderList,
}: UserColumnsProps): TableColumnInterface<UserInterface>[] => {
  return [
    {
      title: 'User ID',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Organization',
      dataIndex: 'project_id',
      key: 'project_id',
      render: (_text: string, record: UserInterface): React.ReactNode => {
        const project = contentProviderList.find(item => item.id === record.project_id);
        return <div>{project?.title || '-'}</div>;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      render: (_text: string, record: UserInterface): React.ReactNode => (
        <Space size="middle">
          <Button key="edit" onClick={() => onClickEditButton(record.id)}>
            Edit
          </Button>
          <Button key="delete" onClick={() => onClickDeleteButton(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];
};
