import { Flex, Form, FormInstance, TableColumnsType } from 'antd';
import * as Component from 'components';
import { TransferDataSourceInterface } from 'components/transfer/Transfer';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import seriesRuleHandler from './seriesRuleHandler';

export const SeriesRule: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  const { id } = useParams<{ id: string }>();
  const [, setRefreshKey] = useState(0);
  const { dataHandler, filterOption } = seriesRuleHandler({ id: id!, form, setRefreshKey });

  const columns: TableColumnsType<TransferDataSourceInterface> = [
    {
      dataIndex: 'id',
      title: 'Id',
      width: '10%',
    },
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, record): React.ReactNode => {
        return (
          <Flex align="center" gap="small">
            <span>{record.name}</span>
          </Flex>
        );
      },
    },
  ];

  return (
    <div>
      <h3 style={{ display: 'flex', alignItems: 'center' }}>
        <span>Series</span>
        <span
          style={{
            flex: 1,
            borderBottom: '1px solid #666',
            marginLeft: '8px',
            height: '0.5em',
          }}
        />
      </h3>
      <Form.Item name="series" noStyle>
        <Component.TransferComponent
          dataHandler={dataHandler}
          filterOption={filterOption}
          columns={columns}
        />
      </Form.Item>
    </div>
  );
};
