import { Flex, TableColumnsType } from 'antd';
import * as Component from 'components';
import { TransferDataSourceInterface } from 'components/transfer/Transfer';
import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import contentListHandlers from '../../../handlers/contentListHandlers';
import './ContentList.css';

const ContentList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { filterOption, dataHandler } = contentListHandlers({ id: id! });
  const columns: TableColumnsType<TransferDataSourceInterface> = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, record): React.ReactNode => {
        return (
          <Flex align="center" gap="small">
            <span>{record.name}</span>
          </Flex>
        );
      },
    },
    {
      dataIndex: 'type',
      title: 'Type',
    },
  ];

  return (
    <Flex align="start" gap="middle" vertical>
      <Component.TransferComponent
        disabled={false}
        dataHandler={dataHandler}
        filterOption={filterOption}
        columns={columns}
      />
    </Flex>
  );
};

export default observer(ContentList);
