import { Button, Card, DatePicker, Form, FormInstance, Input, Space } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { RevenueInterface } from 'content/type';
import React from 'react';
import { getRevenueFilterHandler } from './revenueFilterHandler';

type RevenueFilterProps = {
  form: FormInstance;
  tableData: RevenueInterface[];
  setFilters: (filters: Record<string, FilterValue | null>) => void;
  setSelectedRow: (row: string | null) => void;
};

export const RevenueFilter: React.FC<RevenueFilterProps> = ({
  form,
  setFilters,
  setSelectedRow,
}: RevenueFilterProps) => {
  // Select 컴포넌트용 옵션 생성 함수

  const { handleFilterSubmit, handleReset } = getRevenueFilterHandler(
    form,
    setFilters,
    setSelectedRow
  );

  return (
    <Card style={{ marginBottom: 20 }}>
      <Form form={form} layout="horizontal" onFinish={handleFilterSubmit}>
        <div>
          <Form.Item name="start_yearmon" label="Start Date">
            <DatePicker
              picker="month"
              style={{ width: '10%' }}
              placeholder="Select start date"
              format="YYYY-MM"
            />
          </Form.Item>
          <Form.Item name="end_yearmon" label="End Date">
            <DatePicker
              picker="month"
              style={{ width: '10%' }}
              placeholder="Select end date"
              format="YYYY-MM"
            />
          </Form.Item>
        </div>
        {/* <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
            gap: '16px',
          }}
        > */}
        <Form.Item name="content_provider" label="Content Provider">
          <Input style={{ width: '300px' }} />
          {/* <Select
              allowClear
              placeholder="Select provider"
              mode="multiple"
              style={{ width: '100%' }}
            >
              {createSelectOptions('content_provider').map(value => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select> */}
        </Form.Item>

        <Form.Item name="title_en" label="Title">
          {/* <Select allowClear placeholder="Select title" mode="multiple" style={{ width: '100%' }}>
              {createSelectOptions('title_en').map(value => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select> */}
          <Input style={{ width: '300px' }} />
        </Form.Item>
        {/* </div> */}

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Apply Filters
            </Button>
            <Button onClick={handleReset}>Reset</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
