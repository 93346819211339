import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { TableColumnInterface } from 'components/table/Table';
import { CategoryInterface } from 'content/type';
import React from 'react';

export const getCategoryColumns = (
  handleOnClickTitle: (id: number) => void,
  handleOnClickDeleteButton: (id: number) => void,
  handleOnClickSetting: (id: number) => void
): TableColumnInterface<CategoryInterface>[] => {
  return [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: CategoryInterface) => (
        <a
          href="#"
          className="people-title"
          onClick={() => handleOnClickTitle(Number(record.id) || 0)}
        >
          {text.toLowerCase()}
        </a>
      ),
    },
    {
      title: 'value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      dataIndex: 'actions',
      render: (_text: string, record: CategoryInterface) => (
        <Space>
          <Button
            icon={<SettingOutlined />}
            onClick={() => handleOnClickSetting(Number(record.id) || 0)}
            className="action-btn"
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleOnClickDeleteButton(Number(record.id) || 0)}
            className="action-btn delete-btn"
          />
        </Space>
      ),
    },
  ];
};
