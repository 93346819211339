import { Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import * as Component from '../../../../shared/components';
import * as SeasonComponent from './components';

const SeasonAdd: React.FC = () => {
  const [form] = Form.useForm();

  return (
    <Component.BasePageComponent>
      <SeasonComponent.SeasonDetailComponent form={form} actionTitle="ADD" />
    </Component.BasePageComponent>
  );
};

export default observer(SeasonAdd);
