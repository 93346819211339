import * as Component from 'components';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { contentProviderService } from '../../services/contentProviderService';
import { ContentProviderInterface } from '../../type/contentProviderType';
import { getContentProviderColumns } from './ContentProviderColumns';
import { tableHandlers } from './handlers';

const ContentProviderPage: React.FC = () => {
  const navigate = useNavigate();
  const { onClickEditButton, onClickDeleteButton, handleAddContentProviderClick } = tableHandlers({
    navigate,
  });

  const columns = getContentProviderColumns({ onClickEditButton, onClickDeleteButton });

  const fetchContentProvider = async (
    page: number,
    pageSize: number
  ): Promise<{ data: ContentProviderInterface[]; count: number }> => {
    const contentProviderList = await contentProviderService.fetch({
      page: page.toString(),
      page_size: pageSize.toString(),
      type: 'cp',
    });
    return {
      data: contentProviderList.results,
      count: contentProviderList.count,
    };
  };

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<ContentProviderInterface>
        tableName="Content Provider"
        columns={columns}
        dataFetch={fetchContentProvider}
        handleAddClick={handleAddContentProviderClick}
      />
    </Component.BasePageComponent>
  );
};

export default observer(ContentProviderPage);
