import { contentProviderService } from 'account/services/contentProviderService';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { ContentProviderInterface } from '../../type/contentProviderType';
import * as UserComponent from './components';
import { detailHandlers } from './handlers';

const UserAdd: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { handleOnClickAddButton } = detailHandlers({ id: -1, form, navigate });
  const [contentProviderList, setContentProviderList] = useState<ContentProviderInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchContentProvider = async (): Promise<void> => {
      const contentProviderList = await contentProviderService.fetch({
        page_size: '100',
      });
      setContentProviderList(contentProviderList.results);
    };
    fetchContentProvider().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <UserComponent.UserDetailComponent
          form={form}
          actionTitle="CREATE"
          onSubmit={handleOnClickAddButton}
          contentProviderList={contentProviderList}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(UserAdd);
