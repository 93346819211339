import { Form } from 'antd';
import * as Component from 'components';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { contentProviderService } from '../../services/contentProviderService';
import { ContentProviderInterface } from '../../type/contentProviderType';
import * as ContentProviderComponent from './components';
import { detailHandlers } from './handlers';

const ContentProviderEdit: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [contentProvider, setContentProvider] = useState<ContentProviderInterface | null>(null);
  const { handleOnClickEditButton } = detailHandlers({ navigate, form, id: id || '' });
  useEffect(() => {
    const fetchContentProvider = async (): Promise<void> => {
      const contentProvider = await contentProviderService.fetchById(Number(id));
      setContentProvider(contentProvider);
      form.setFieldsValue(contentProvider);
    };
    fetchContentProvider().then();
  }, [id]);

  return (
    <Component.BasePageComponent>
      <ContentProviderComponent.ContentProviderDetailComponent
        form={form}
        contentProvider={contentProvider || undefined}
        actionTitle="SAVE"
        onSubmit={handleOnClickEditButton}
      />
    </Component.BasePageComponent>
  );
};

export default observer(ContentProviderEdit);
