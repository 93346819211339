import axios from 'axios';
import { BASE_URL, CONTENT_API_PATH, REVENUE_API_PATH } from 'constants/api';
import {
  ContentInterface,
  DubbingInterface,
  RevenueInterface,
  SubtitleInterface,
  ThumbnailInterface,
  UploadThumbnailInterface,
} from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class ContentService {
  constructor(private apiService: ApiService<ContentInterface>) {}

  async fetch(query: Record<string, string>): Promise<FetchResult<ContentInterface>> {
    return await this.apiService.fetch(query);
  }

  async fetchById(id: number): Promise<ContentInterface> {
    return await this.apiService.fetchById(id);
  }

  async fetchByIds(ids: number[]): Promise<ContentInterface[]> {
    const response = await this.apiService.fetchByIds(ids);
    return response.results;
  }

  async update(id: number, content: Partial<ContentInterface>): Promise<void> {
    await this.apiService.update(id, content);
  }

  // Video Transcode
  async videoTranscode(contentId: number): Promise<void> {
    const response = await axios.post(
      `${BASE_URL}${CONTENT_API_PATH}${contentId}/video-transcode/`
    );
    return response.data;
  }

  // Subtitle
  async fetchSubtitleList(contentId: number): Promise<SubtitleInterface[]> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}${contentId}/subtitle/`);
    return response.data;
  }

  public async uploadSubtitle(contentId: number, subtitles: SubtitleInterface[]): Promise<void> {
    await Promise.all(
      subtitles.map(async (subtitle: SubtitleInterface) => {
        const formData = new FormData();
        Object.entries(subtitle).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await axios.post(
          `${BASE_URL}${CONTENT_API_PATH}${contentId}/subtitle/`,
          formData
        );
        return response.data;
      })
    );
    await Promise.all(
      subtitles.map(async (subtitle: SubtitleInterface) => {
        const formData = new FormData();
        Object.entries(subtitle).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await axios.patch(
          `${BASE_URL}${CONTENT_API_PATH}${contentId}/subtitle/${subtitle.id}/`,
          formData
        );
        return response.data;
      })
    );
  }

  // Dubbing
  async fetchDubbingList(contentId: number): Promise<FetchResult<DubbingInterface>> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}${contentId}/dubbing/`);
    return response.data;
  }

  public async uploadDubbing(contentId: number, dubbings: DubbingInterface[]): Promise<void> {
    await Promise.all(
      dubbings.map(async (dubbing: DubbingInterface) => {
        const formData = new FormData();
        Object.entries(dubbing).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await axios.post(
          `${BASE_URL}${CONTENT_API_PATH}${contentId}/dubbing/`,
          formData
        );
        return response.data;
      })
    );
  }

  // Thumbnail
  async fetchThumbnailList(contentId: number): Promise<ThumbnailInterface[]> {
    const response = await axios.get(`${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/`);
    return response.data;
  }

  async updateThumbnail(contentId: number, thumbnail: ThumbnailInterface): Promise<void> {
    const response = await axios.patch(
      `${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/${thumbnail.id}/`,
      thumbnail
    );
    return response.data;
  }

  public async uploadThumbnail(
    contentId: number,
    thumbnails: UploadThumbnailInterface[]
  ): Promise<void> {
    await Promise.all(
      thumbnails.map(async (thumbnail: UploadThumbnailInterface) => {
        const formData = new FormData();
        if (thumbnail.file) {
          formData.append('file', thumbnail.file);
        }
        if (thumbnail.is_representative) {
          formData.append('is_representative', thumbnail.is_representative.toString());
        }

        const response = await axios.post(
          `${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/`,
          formData
        );
        return response.data;
      })
    );
  }

  public async removeThumbnail(contentId: number, thumbnails: ThumbnailInterface[]): Promise<void> {
    await Promise.all(
      thumbnails.map(async (thumbnail: ThumbnailInterface) => {
        const response = await axios.delete(
          `${BASE_URL}${CONTENT_API_PATH}${contentId}/thumbnail/${thumbnail.id}/`
        );
        return response.data;
      })
    );
  }

  // Revenue
  async fetchRevenueShareData(
    queryString: Record<string, string>
  ): Promise<FetchResult<RevenueInterface>> {
    const qs = Object.entries(queryString)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const response = await axios.get(`${BASE_URL}${REVENUE_API_PATH}?${qs}`);
    return response.data;
  }
}

export const contentService = new ContentService(new ApiService(CONTENT_API_PATH));
