import { contractService } from '../../../services/contractService';

interface UploadHandlers {
  handleFileUpload: (file: File) => Promise<void>;
}

const uploadHandlers = (): UploadHandlers => {
  const handleFileUpload = async (file: File): Promise<void> => {
    try {
      await contractService.upload(file);
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  return {
    handleFileUpload,
  };
};

export default uploadHandlers;
