import { TransferDataHandlerInterface } from 'components/transfer/Transfer';
import { contentService } from 'content/service/contentService';
import { playlistContentService } from 'content/service/playlistContentService';
import { ContentInterface } from 'content/type';

interface ContentListHook {
  filterOption: (input: string, item: ContentInterface) => boolean;
  dataHandler: TransferDataHandlerInterface;
}

const contentListHandlers = ({ id }: { id: string }): ContentListHook => {
  const filterOption = (input: string, item: ContentInterface): boolean =>
    item.title?.includes(input);

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (query: Record<string, string>) => {
      const contents = await contentService.fetch(query);
      return {
        data: contents.results.map((content: ContentInterface) => ({
          ...content,
          key: content.id,
          name: content.title,
          disabled: false,
        })),
        count: contents.count,
      };
    },
    fetchTargetData: async (query: Record<string, string>) => {
      query = {
        ...query,
        playlist_id: id!,
      };
      const playlistContents = await playlistContentService.fetch(query);
      return {
        data: playlistContents.results.map((content: ContentInterface) => ({
          ...content,
          key: content.id,
          name: content.title,
          disabled: false,
        })),
        count: playlistContents.count,
      };
    },
    createWithParentId: async (ids: number[]) => {
      const playlistContents = await playlistContentService.fetch({
        playlist_id: id!,
      });
      const playlistContentIds = playlistContents.results.map(content => content.id);
      const rule = {
        value_type: 'content',
        value: [...new Set([...playlistContentIds, ...ids])].join(','),
      };
      await playlistContentService.upsertPlaylistRule(Number(id), [rule]);
    },
    deleteWithParentId: async (ids: number[]) => {
      const playlistContents = await playlistContentService.fetch({
        playlist_id: id!,
      });
      const playlistContentIds = playlistContents.results.map(content => content.id);
      const rule = {
        value_type: 'content',
        value: playlistContentIds.filter(id => !ids.includes(id)).join(','),
      };
      await playlistContentService.upsertPlaylistRule(Number(id), [rule]);
    },
  };

  return {
    filterOption,
    dataHandler,
  };
};

export default contentListHandlers;
