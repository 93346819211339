import { contentProviderService } from 'account/services/contentProviderService';
import { ContentProviderInterface } from 'account/type/contentProviderType';
import { FormInstance, message } from 'antd';
import { NavigateFunction } from 'react-router-dom';

interface OrganizationDetailHook {
  form: FormInstance;
  navigate: NavigateFunction;
  id: string;
}

interface OrganizationDetailHookReturn {
  handleOnClickAddButton: () => Promise<void>;
  handleOnClickEditButton: () => Promise<void>;
}

const detailHandlers = ({
  form,
  navigate,
  id,
}: OrganizationDetailHook): OrganizationDetailHookReturn => {
  const handleOnClickAddButton = async (): Promise<void> => {
    const loadingMessage = message.loading('Creating...', 0);
    try {
      const values = await form.validateFields();
      const newContentProvider: ContentProviderInterface = {
        id: -1,
        title: values.title,
        description: values.description,
        title_en: values.title_en || undefined,
        slug: values.slug || undefined,
        country: values.country || undefined,
        type: 'cp',
      };
      await contentProviderService.create(newContentProvider);
      loadingMessage();
      navigate('/content-provider');
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  const handleOnClickEditButton = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    try {
      const values = await form.validateFields();
      await contentProviderService.update(Number(id), {
        ...values,
        title_en: values.title_en || undefined,
        slug: values.slug || undefined,
        country: values.country || undefined,
      });
      loadingMessage();
      navigate('/content-provider');
    } catch (error) {
      loadingMessage();
      message.error('Failed to update : ' + error);
    }
  };

  return {
    handleOnClickAddButton,
    handleOnClickEditButton,
  };
};

export default detailHandlers;
