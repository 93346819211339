import { FormInstance, Table } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { RevenueInterface } from 'content/type/revenueType';
import React, { useEffect, useState } from 'react';
import { revenueService } from 'src/domain/revenue/services';
import './RevenueTable.css';
import { useRevenueDashboardColumns } from './revenueTableColumns';
import { getRevenueTableHandler } from './revenueTableHandler';

type RevenueTableProps = {
  form: FormInstance;
  filters: Record<string, FilterValue | null>;
  selectedRow: string | null;
  selectedMonth: string | null;
  setSelectedRow: (row: string | null) => void;
  setFilters: (filters: Record<string, FilterValue | null>) => void;
};
export const RevenueTable: React.FC<RevenueTableProps> = ({
  form,
  filters,
  selectedRow,
  selectedMonth,
  setSelectedRow,
  setFilters,
}: RevenueTableProps) => {
  const [tableData, setTableData] = useState<RevenueInterface[]>([]);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = useRevenueDashboardColumns(filters);

  const { handleTableChange, handleRowClick } = getRevenueTableHandler({
    form,
    filters,
    selectedRow,
    setSelectedRow,
    setTableData,
    setFilters,
    setCurrent,
  });

  useEffect(() => {
    setIsLoading(true);
    const dataFetch = async (): Promise<void> => {
      const queryParam: Record<string, string> = {
        page: current.toString(),
        page_size: pageSize.toString(),
        content_id: String(filters.content_id?.[0] ?? ''),
        title: String(filters.title?.[0] ?? ''),
        start_yearmon: selectedMonth ? selectedMonth : String(filters.start_yearmon?.[0] ?? ''),
        end_yearmon: selectedMonth ? selectedMonth : String(filters.end_yearmon?.[0] ?? ''),
        content_provider_id: String(filters.content_provider_id?.[0] ?? ''),
        group_by: String(filters.group_by?.[0] ?? ''),
        order_by: String(filters.order_by?.[0] ?? ''),
        order: String(filters.order?.[0] ?? ''),
        paging: String(filters.paging?.[0] ?? ''),
      };

      Object.keys(queryParam).forEach(key => {
        if (queryParam[key] === '') {
          delete queryParam[key];
        }
      });

      if (filters.group_by?.[0] === 'content_yearmon') {
        delete queryParam.group_by;
      }

      const data = await revenueService.fetch(queryParam);
      setPageSize(data.page_size);
      setTotalSize(data.count);
      setTableData(data.results);
      setIsLoading(false);
    };
    dataFetch();
  }, [selectedMonth, filters, current, pageSize]);

  return (
    <Table<RevenueInterface>
      loading={isLoading}
      columns={columns}
      dataSource={tableData}
      rowClassName={record =>
        `${record.content_provider}:-${record.title_en}:-${record.yearmon}` === selectedRow
          ? 'selected-row'
          : ''
      }
      rowKey={record => `${record.content_provider}:-${record.title_en}:-${record.yearmon}`}
      bordered
      pagination={{
        current,
        pageSize: pageSize,
        total: totalSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
      }}
      expandable={{
        defaultExpandAllRows: true,
      }}
      onChange={handleTableChange}
      onRow={record => ({
        onClick: () => handleRowClick(record),
      })}
    />
  );
};
