import * as Component from 'components';
import { transcodeHandlers } from 'content/page/content/handlers';
import { contentService } from 'content/service/contentService';
import { ContentStreamManifestInterface } from 'content/type';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Transcode.css';

export const TranscodeComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [ableToTranscode, setAbleToTranscode] = useState<boolean>(false);
  const [contentStreamManifests, setContentStreamManifests] = useState<
    ContentStreamManifestInterface[]
  >([]);
  const { handleRefresh, handleStartTranscode } = transcodeHandlers({
    id: Number(id),
    setContent: setContentStreamManifests,
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const content = await contentService.fetchById(Number(id));
      const contentStreamManifests = content.content_stream_manifests;
      setContentStreamManifests(contentStreamManifests);
      setAbleToTranscode(content.contents_file?.file !== null);
    };
    fetchData();
  }, [id]);

  return (
    <div>
      <button
        className="startTranscodeButton"
        onClick={() => handleStartTranscode()}
        disabled={
          contentStreamManifests.some(manifest => manifest.status === 'in_progress') ||
          !ableToTranscode
        }
        style={{ marginRight: '10px' }}
      >
        Start Transcode
      </button>
      <button className="refreshButton" onClick={() => handleRefresh()} disabled={!ableToTranscode}>
        Refresh
      </button>
      {ableToTranscode ? (
        <></>
      ) : (
        <div className="transcodeInfo">
          <span>This video is not able to transcode</span>
        </div>
      )}
      <div className="transcodeContainer">
        {contentStreamManifests.length === 0 ? (
          <div>Empty Transcode Video</div>
        ) : (
          contentStreamManifests.map((manifest, index) => (
            <div key={index} className="transcodeItem">
              <div className="transcodeHeader">
                <span className="transcodePreset">
                  {manifest.type} {manifest.preset}
                </span>
                <span className={`transcodeStatus ${manifest.status}`}>{manifest.status}</span>
              </div>
              {manifest.stream_url && (
                <div className="transcodeUrl">
                  <span className="urlLabel">URL:</span>
                  <a href={manifest.stream_url}>
                    {manifest.stream_url.length > 30
                      ? manifest.stream_url.substring(0, 30) + '...'
                      : manifest.stream_url}
                  </a>
                  <div className="transcodePreview">
                    <span className="previewLabel">Preview:</span>
                    <Component.VideoPlayerComponent
                      options={{
                        sources: [{ src: manifest.stream_url, type: 'application/x-mpegURL' }],
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TranscodeComponent;
