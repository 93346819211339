import { FormInstance, message } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { userService } from '../../../services/userService';
import { UserInterface } from '../../../type/userType';

interface DetailHandlersHook {
  id: number;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface DetailHandlersReturn {
  handleOnClickAddButton: () => Promise<void>;
  handleOnClickEditButton: () => Promise<void>;
}

const detailHandlers = ({ id, form, navigate }: DetailHandlersHook): DetailHandlersReturn => {
  const handleOnClickAddButton = async (): Promise<void> => {
    const loadingMessage = message.loading('Creating...', 0);
    try {
      const values = await form.validateFields();
      const newUser: UserInterface = {
        id: -1,
        username: values.username,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        role: values.role,
        project_id: values.project_id,
      };
      const createdUser = await userService.create(newUser);
      loadingMessage();
      navigate('/user', {
        state: {
          message: '사용자가 성공적으로 생성되었습니다',
          userId: createdUser.id,
        },
      });
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  const handleOnClickEditButton = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    try {
      const values = await form.validateFields();
      await userService.update(id, values);
      loadingMessage();
      navigate('/user', {
        state: {
          message: '사용자 정보가 성공적으로 수정되었습니다',
          userId: id,
        },
      });
    } catch (error) {
      loadingMessage();
      message.error('Failed to update : ' + error);
    }
  };

  return {
    handleOnClickAddButton,
    handleOnClickEditButton,
  };
};

export default detailHandlers;
