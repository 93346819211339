import { Button } from 'antd';
import React from 'react';
import './Button.css';

interface CustomButtonProps {
  type?: 'text' | 'link' | 'primary' | 'default' | 'dashed';
  icon?: React.ReactNode;
  text: string;
  color?: string;
  hoverColor?: string;
  size?: 'large' | 'middle' | 'small';
  onClick?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  type = 'primary',
  icon,
  text,
  onClick,
  hoverColor = '#1890ff',
  size = 'middle',
}) => {
  return (
    <Button
      className="color-button"
      type={type}
      size={size}
      icon={icon ? icon : undefined}
      style={{
        backgroundColor: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
      onClick={onClick || ((): void => {})}
      onMouseEnter={e => {
        e.currentTarget.style.backgroundColor = hoverColor;
      }}
      onMouseLeave={e => {
        e.currentTarget.style.backgroundColor = 'white';
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
