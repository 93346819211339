import { Button, Form, FormInstance, Input, Modal, Select, Tag } from 'antd';
import { AGE_RATING } from 'constants/index';
import detailHandlers from 'content/page/series/handlers/detailHandlers';
import { categoryService, peopleService } from 'content/service';
import { CategoryInterface, PeopleInterface } from 'content/type';
import React, { useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../../../shared/components';
import { SeriesInterface } from '../../../../type/seriesType';

interface SeriesDetailProps {
  form: FormInstance;
  series?: SeriesInterface;
  contentCategoryList?: CategoryInterface[];
  actionTitle: string;
}

const SeriesDetail: React.FC<SeriesDetailProps> = ({
  form,
  series,
  contentCategoryList,
  actionTitle,
}) => {
  const [selectedCategories, setSelectedCategories] = React.useState<number[]>([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState<CategoryInterface[]>([]);
  const [peoples, setPeoples] = React.useState<PeopleInterface[]>([]);
  const navigate = useNavigate();
  const {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleModalCancel,
    handleModalOk,
    handleEditCategories,
  } = detailHandlers({
    navigate,
    form,
    selectedCategories,
    setIsModalVisible,
  });

  const handleOnClickButtonClick = series
    ? handleOnClickEditButtonClick
    : handleOnClickAddButtonClick;

  useEffect(() => {
    const fetchCategoryList = async (): Promise<CategoryInterface[]> => {
      return await categoryService.fetch();
    };
    fetchCategoryList().then(setCategoryList);

    if (contentCategoryList) {
      setSelectedCategories(contentCategoryList.map((category: CategoryInterface) => category.id));
    }

    const init = async (): Promise<void> => {
      if (!series) {
        return;
      }

      const data = await peopleService.fetch({
        page: '1',
        page_size: '100',
        series__id: series.id.toString(),
      });

      form.setFieldsValue({
        cast: data.results.map((people: PeopleInterface) => people.id.toString()),
      });
      setPeoples(data.results);
    };
    init();
  }, [form, series, contentCategoryList]);

  const searchPeople = async (value: string): Promise<void> => {
    const response = await peopleService.fetch({
      page: '1',
      page_size: '10',
      search: value,
    });
    setPeoples(response.results);
  };

  return (
    <div>
      <div className="series-details-page">
        <h1>Series: {series?.title}</h1>
        <Form form={form} layout="vertical" className="series-details-form">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
            initialValue={series?.title || 'asdasd'}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="age_rating"
            label="Age Rating"
            initialValue={series?.age_rating}
            rules={[{ required: true, message: 'Please enter a age rating' }]}
          >
            <Select
              options={AGE_RATING.map(ageRating => ({ label: ageRating, value: ageRating }))}
            />
          </Form.Item>
          <Form.Item name="synopsis" label="Synopsis" initialValue={series?.synopsis}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="release_date_start"
            label="Release Start Date"
            initialValue={series?.release_date_start}
          >
            <Flatpickr
              style={{ width: '30%', backgroundColor: 'white', borderWidth: '1px' }}
              onChange={([date]) => {
                const formattedDate = date.toISOString().slice(0, 10);
                form.setFieldValue(['release_date_start'], formattedDate);
              }}
            />
          </Form.Item>
          <Form.Item
            name="release_date_end"
            label="Release End Date"
            initialValue={series?.release_date_end}
          >
            <Flatpickr
              style={{ width: '30%', backgroundColor: 'white', borderWidth: '1px' }}
              onChange={([date]) => {
                const formattedDate = date.toISOString().slice(0, 10);
                form.setFieldValue(['release_date_end'], formattedDate);
              }}
            />
          </Form.Item>
          <Form.Item name="produce_country" label="Produce Country">
            <Input />
          </Form.Item>
          <Form.Item name="cast" label="Cast">
            <Select
              onSearch={value => {
                searchPeople(value);
              }}
              mode="multiple"
              options={peoples.map(people => ({
                label: people.name,
                value: people.id.toString(),
              }))}
              filterOption={false}
            ></Select>
          </Form.Item>

          <Form layout="inline">
            <Form.Item
              name="categories"
              style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
            >
              <span style={{ marginRight: 8, cursor: 'pointer' }}>Categories</span>
              <Button type="link" onClick={handleEditCategories}>
                Edit
              </Button>
              <div style={{ marginTop: '4px', marginBottom: '8px' }}>
                {categoryList
                  .filter(category => selectedCategories.includes(category.id))
                  .map(category => (
                    <Tag
                      key={category.id}
                      color="blue"
                      style={{ margin: '4px', cursor: 'pointer' }}
                      onClick={() =>
                        window.open(
                          `/category/edit/${category.id}`,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      {category.name}
                    </Tag>
                  ))}
              </div>
            </Form.Item>
          </Form>
        </Form>
      </div>
      <div className="series-detail-update-button">
        <Component.ColorButtonComponent text={actionTitle} onClick={handleOnClickButtonClick} />
      </div>
      <Modal
        title="Edit Categories"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Select
          mode="multiple"
          value={selectedCategories}
          onChange={setSelectedCategories}
          style={{ width: '100%' }}
        >
          {categoryList.map(category => (
            <Select.Option key={category.id} value={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default SeriesDetail;
