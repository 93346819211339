import { message } from 'antd';
import { NavigateFunction } from 'react-router-dom';

interface TableHandlersProps {
  navigate: NavigateFunction;
}

interface TableHandlers {
  handleOnClickDeleteButton: (id: number) => Promise<void>;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleAddClick: () => void;
  handleBulkDelete: (ids: number[]) => Promise<void>;
}

const tableHandlers = ({ navigate }: TableHandlersProps): TableHandlers => {
  const handleOnClickDeleteButton = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/contract/edit/${id.toString()}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/contract/edit/${id.toString()}`);
  };

  const handleAddClick = (): void => {
    navigate('/contract/add');
  };

  const handleBulkDelete = async (): Promise<void> => {
    try {
      message.info('You do not have permission');
      return;
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
    }
  };
  return {
    handleOnClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleAddClick,
    handleBulkDelete,
  };
};

export default tableHandlers;
