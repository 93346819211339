import { Button, Form, FormInstance, Input, InputNumber, Modal, Select, Tag } from 'antd';
import { categoryService, peopleService } from 'content/service';
import { CategoryInterface, PeopleInterface } from 'content/type';
import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../../../../shared/components';
import { SeasonInterface } from '../../../../../type/seasonType';
import detailHandlers from '../../../../season/handlers/detailHandlers';

interface SeasonDetailProps {
  form: FormInstance;
  season?: SeasonInterface;
  contentCategoryList?: CategoryInterface[];
  actionTitle: string;
  readonly?: boolean;
}

const SeasonDetail: React.FC<SeasonDetailProps> = ({
  form,
  season,
  contentCategoryList,
  actionTitle,
  readonly = false,
}) => {
  const [selectedCategories, setSelectedCategories] = React.useState<number[]>([]);
  const [categoryList, setCategoryList] = React.useState<CategoryInterface[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [peoples, setPeoples] = React.useState<PeopleInterface[]>([]);
  const [] = useState([]);
  const navigate = useNavigate();
  const {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleModalCancel,
    handleModalOk,
    handleEditCategories,
  } = detailHandlers({
    navigate,
    form,
    selectedCategories,
    setIsModalVisible,
  });

  const handleOnClickButtonClick = season
    ? handleOnClickEditButtonClick
    : handleOnClickAddButtonClick;

  useEffect(() => {
    const fetchCategoryList = async (): Promise<CategoryInterface[]> => {
      return await categoryService.fetch();
    };
    fetchCategoryList().then(setCategoryList);

    if (contentCategoryList) {
      setSelectedCategories(contentCategoryList.map((category: CategoryInterface) => category.id));
    }

    const init = async (): Promise<void> => {
      if (!season) {
        return;
      }

      const data = await peopleService.fetch({
        page: '1',
        page_size: '100',
        seasons__id: season.id.toString(),
      });

      form.setFieldsValue({
        cast: data.results.map((people: PeopleInterface) => people.id.toString()),
      });
      setPeoples(data.results);
    };
    init();
  }, [season, contentCategoryList]);

  const searchPeople = async (value: string): Promise<void> => {
    const response = await peopleService.fetch({
      page: '1',
      page_size: '10',
      search: value,
    });
    setPeoples(response.results);
  };

  return (
    <div>
      <div className="season-details-page">
        <h1>Season: {season?.title}</h1>
        <Form form={form} layout="vertical" className="season-details-form">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
            initialValue={season?.title || ''}
          >
            <Input disabled={readonly} />
          </Form.Item>
          <Form.Item label="Title En" name="title_en">
            <Input disabled={readonly} />
          </Form.Item>
          <Form.Item name="synopsis" label="Synopsis" initialValue={season?.synopsis}>
            <Input.TextArea disabled={readonly} />
          </Form.Item>
          <Form.Item
            name="season_number"
            label="Season Number"
            initialValue={season?.season_number}
          >
            <InputNumber disabled={readonly} />
          </Form.Item>
          <Form.Item
            name="release_date_start"
            label="Release Start Date"
            initialValue={season?.release_date_start}
          >
            <Flatpickr
              style={{ width: '30%', backgroundColor: 'white', borderWidth: '1px' }}
              onChange={([date]) => {
                const formattedDate = date.toISOString().slice(0, 10);
                form.setFieldValue(['release_date_start'], formattedDate);
              }}
            />
          </Form.Item>
          <Form.Item
            name="release_date_end"
            label="Release End Date"
            initialValue={season?.release_date_end}
          >
            <Flatpickr
              style={{ width: '30%', backgroundColor: 'white', borderWidth: '1px' }}
              onChange={([date]) => {
                const formattedDate = date.toISOString().slice(0, 10);
                form.setFieldValue(['release_date_end'], formattedDate);
              }}
            />
          </Form.Item>
          <Form.Item name="cast" label="Cast">
            <Select
              onSearch={value => {
                searchPeople(value);
              }}
              mode="multiple"
              options={peoples.map(people => ({
                label: people.name,
                value: people.id.toString(),
              }))}
              filterOption={false}
            ></Select>
          </Form.Item>
          <Form layout="inline">
            <Form.Item
              name="categories"
              style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
            >
              <span style={{ marginRight: 8, cursor: 'pointer' }}>Categories</span>
              <Button type="link" onClick={handleEditCategories}>
                Edit
              </Button>
              <div style={{ marginTop: '4px', marginBottom: '8px' }}>
                {categoryList
                  .filter(category => selectedCategories.includes(category.id))
                  .map(category => (
                    <Tag
                      key={category.id}
                      color="blue"
                      style={{ margin: '4px', cursor: 'pointer' }}
                      onClick={() =>
                        window.open(
                          `/category/edit/${category.id}`,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      {category.name}
                    </Tag>
                  ))}
              </div>
            </Form.Item>
          </Form>
          <Form.Item name="season" label="Season" initialValue={season?.series}>
            <Input disabled={readonly} />
          </Form.Item>
          {season?.program_meta && (
            <>
              <h2>ODX Meta</h2>
              <Form.Item name={['program_meta', 'program_id']} label="Program ID">
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'program_slug']} label="Program Slug">
                <Input readOnly />
              </Form.Item>
              {season.program_meta.program_image.map((image, index) => (
                <div key={index}>
                  <img src={image['@url']} alt="program_image" style={{ width: '100px' }} />
                  <Form.Item
                    key={index}
                    name={['program_meta', 'program_image', index, '@url']}
                    label={`Program Image - ${image['@type']}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                </div>
              ))}
              <Form.Item name={['program_meta', 'program_enable']} label="Program Enable">
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'ingest_source']} label="Ingest Source">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name={['program_meta', 'program_release_start']}
                label="Program Release Start"
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'program_release_end']} label="Program Release End">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name={['program_meta', 'program_publish_start']}
                label="Program Publish Start"
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item name={['program_meta', 'program_publish_end']} label="Program Publish End">
                <Input readOnly />
              </Form.Item>
            </>
          )}
        </Form>
      </div>
      <div className="season-detail-update-button">
        {!readonly && (
          <Component.ColorButtonComponent text={actionTitle} onClick={handleOnClickButtonClick} />
        )}
      </div>
      <Modal
        title="Edit Categories"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Select
          mode="multiple"
          value={selectedCategories}
          onChange={setSelectedCategories}
          style={{ width: '100%' }}
        >
          {categoryList.map(category => (
            <Select.Option key={category.id} value={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default SeasonDetail;
