import { FormInstance, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import axios from 'axios';
import { THUMBNAIL_UPLOAD_STATE } from 'constants/index';
import { contentService } from 'content/service';
import { ThumbnailInterface, UploadThumbnailInterface } from 'content/type';

interface ThumbnailHandlersProps {
  form: FormInstance;
  contentId: number;
  selectedThumbnail: ThumbnailInterface | null;
  setThumbnailList: (thumbnailList: ThumbnailInterface[]) => void;
  setSelectedThumbnail: (thumbnail: ThumbnailInterface | null) => void;
}

interface ThumbnailHandlers {
  handleChange: (info: UploadChangeParam<UploadFile<UploadThumbnailInterface>>) => void;
  handleThumbnailRemove: (thumbnail: ThumbnailInterface) => void;
  handleThumbnailSelect: (thumbnail: ThumbnailInterface | null) => void;
  handleThumbnailUpload: () => void;
}

const createUploadThumbnailList = (
  uploadFileList: UploadFile<UploadThumbnailInterface>[]
): ThumbnailInterface[] => {
  let fileId = 0;
  return uploadFileList.map((file: UploadFile<UploadThumbnailInterface>): ThumbnailInterface => {
    const fileUrl = file.originFileObj ? URL.createObjectURL(file.originFileObj) : '';
    return {
      id: fileId++,
      file: fileUrl,
      is_representative: false,
      state: THUMBNAIL_UPLOAD_STATE.UPLOADING,
      originFileObj: file.originFileObj,
    };
  });
};

const updateFormValues = (
  form: FormInstance,
  thumbnailList: ThumbnailInterface[],
  uploadThumbnailList: ThumbnailInterface[]
): void => {
  form.setFieldValue('thumbnailList', thumbnailList);
  form.setFieldValue(
    'uploadThumbnailList',
    uploadThumbnailList.map(thumbnail => ({
      id: thumbnail.id,
      file: thumbnail.originFileObj,
    }))
  );
};

const handleChange =
  (props: ThumbnailHandlersProps) =>
  (info: UploadChangeParam<UploadFile<UploadThumbnailInterface>>): void => {
    const { form, setThumbnailList } = props;
    const uploadFileList = info.fileList || [];
    const thumbnailList = form.getFieldValue('thumbnailList') || [];
    const uploadThumbnailList = createUploadThumbnailList(uploadFileList);

    const newThumbnailList = [
      ...uploadThumbnailList,
      ...thumbnailList.filter(
        (thumbnail: { state: string }) => thumbnail.state === THUMBNAIL_UPLOAD_STATE.UPLOADED
      ),
    ];

    updateFormValues(form, newThumbnailList, uploadThumbnailList);
    setThumbnailList(newThumbnailList);
  };

const handleThumbnailRemove =
  (props: ThumbnailHandlersProps) =>
  (removedThumbnail: ThumbnailInterface): void => {
    const { form, setThumbnailList } = props;
    const thumbnailList = form.getFieldValue('thumbnailList') || [];
    const uploadThumbnailList = form.getFieldValue('uploadThumbnailList') || [];

    const newThumbnailList = thumbnailList.filter(
      (thumbnail: { id: number }) => thumbnail.id !== removedThumbnail.id
    );
    const newUploadThumbnailList = uploadThumbnailList.filter(
      (thumbnail: { id: number }) => thumbnail.id !== removedThumbnail.id
    );

    form.setFieldValue('thumbnailList', newThumbnailList);
    form.setFieldValue('uploadThumbnailList', newUploadThumbnailList);

    setThumbnailList(newThumbnailList);
  };

const handleThumbnailSelect =
  (props: ThumbnailHandlersProps) =>
  (newSelectedThumbnail: ThumbnailInterface | null): void => {
    const { form, selectedThumbnail, setSelectedThumbnail } = props;
    if (selectedThumbnail) {
      selectedThumbnail.is_representative = false;
    }
    if (newSelectedThumbnail) {
      newSelectedThumbnail.is_representative = true;
    }
    form.setFieldValue('selectedThumbnail', newSelectedThumbnail);
    setSelectedThumbnail(newSelectedThumbnail);
  };

const handleThumbnailUpload = (props: ThumbnailHandlersProps) => async (): Promise<void> => {
  const { form, contentId } = props;
  const loadingMessage = message.loading('Updating...', 0);
  const thumbnailList = form.getFieldValue('thumbnailList');
  const selectedThumbnail = form.getFieldValue('selectedThumbnail');
  const uploadThumbnailList = form.getFieldValue('uploadThumbnailList');
  const uploadedThumbnailList = form.getFieldValue('uploadedThumbnailList');

  if (selectedThumbnail) {
    uploadThumbnailList.map((file: { id: number; is_representative: boolean }) => {
      if (file.id === selectedThumbnail.id) {
        file.is_representative = selectedThumbnail.is_representative;
        selectedThumbnail.id = -1;
      }
      return file;
    });
  }

  const removeThumbnails = uploadedThumbnailList.filter(
    (uploadedThumbnail: { id: number }) =>
      !thumbnailList.some(
        (uploadThumbnail: { id: number }) => uploadThumbnail.id === uploadedThumbnail.id
      )
  );

  try {
    if (uploadThumbnailList.length > 0) {
      await contentService.uploadThumbnail(contentId, uploadThumbnailList);
    }
    if (removeThumbnails.length > 0) {
      await contentService.removeThumbnail(contentId, removeThumbnails);
    }
    if (selectedThumbnail && selectedThumbnail.id !== -1) {
      await contentService.updateThumbnail(contentId, selectedThumbnail);
    }
    loadingMessage();
    message.success('upload success');
  } catch (error) {
    loadingMessage();
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      message.error('Failed to update : ' + JSON.stringify(error.response.data));
    }
  }
};

const thumbnailHandlers = (props: ThumbnailHandlersProps): ThumbnailHandlers => ({
  handleChange: handleChange(props),
  handleThumbnailRemove: handleThumbnailRemove(props),
  handleThumbnailSelect: handleThumbnailSelect(props),
  handleThumbnailUpload: handleThumbnailUpload(props),
});

export default thumbnailHandlers;
