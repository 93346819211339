import axios from 'axios';
import { BASE_URL, PLAYLIST_CONTENT_API_PATH } from 'constants/api';
import { ContentInterface } from 'content/type/contentType';
import {
  PlaylistItemInterface,
  PlaylistRuleInterface,
  UpdateRuleInterface,
} from 'content/type/playlistType';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { playlistService } from './playlistService';

class PlaylistContentService {
  constructor(private apiService: ApiService<ContentInterface>) {}

  public async deleteIds(itemIds: number[]): Promise<void> {
    await Promise.all(itemIds.map(async id => this.apiService.delete(id)));
  }

  public async fetch(query?: Record<string, string>): Promise<FetchResult<ContentInterface>> {
    return await this.apiService.fetch(query);
  }

  public async fetchByParentId(
    parentId: number,
    query: Record<string, string>
  ): Promise<PlaylistItemInterface[]> {
    const playlist = await playlistService.fetchById(parentId, query);
    return playlist.playlist_items;
  }

  public async upsertPlaylistRule(playlistId: number, rules: UpdateRuleInterface[]): Promise<void> {
    await axios.patch(`${BASE_URL}${PLAYLIST_CONTENT_API_PATH}${playlistId}/upsert-rule/`, rules);
  }

  public async fetchRules(playlistId: number): Promise<PlaylistRuleInterface[]> {
    const playlist = await axios.get(`${BASE_URL}${PLAYLIST_CONTENT_API_PATH}${playlistId}/rules/`);
    return playlist.data;
  }
}

export const playlistContentService = new PlaylistContentService(
  new ApiService(PLAYLIST_CONTENT_API_PATH)
);
