import { ADMIN_USER_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { UserInterface } from '../type/userType';

export class UserService {
  constructor(private readonly apiService: ApiService<UserInterface>) {}

  public async update(id: number, user: UserInterface): Promise<UserInterface> {
    return await this.apiService.update(id, {
      ...user,
      project_id: user.project_id,
      role: user.role,
    });
  }

  public async delete(id: number): Promise<void> {
    await this.apiService.delete(id);
  }

  public async create(user: UserInterface): Promise<UserInterface> {
    return await this.apiService.create({
      ...user,
      project_id: user.project_id,
      role: user.role,
    });
  }

  public async fetchById(id: number): Promise<UserInterface> {
    const response = await this.apiService.fetchById(id);
    return response;
  }

  public async fetch(queryParams: Record<string, string>): Promise<FetchResult<UserInterface>> {
    return await this.apiService.fetch(queryParams);
  }
}

export const userService = new UserService(new ApiService(ADMIN_USER_API_PATH));
