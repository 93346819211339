import axios from 'axios';
import { BASE_URL } from 'constants/api';
import { FetchResult } from '../type/apiType';

export class ApiService<T> {
  constructor(
    public apiPath: string,
    public subPath: string = '/'
  ) {}

  public async create(item: T): Promise<T> {
    const response = await axios.post(`${BASE_URL}${this.apiPath}`, item);
    return response.data;
  }

  public async update(id: number, data: Partial<T>): Promise<T> {
    const response = await axios.patch(`${BASE_URL}${this.apiPath}${id}/`, data);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    await axios.delete(`${BASE_URL}${this.apiPath}${id}/`);
  }

  public async fetch(queryParams?: Record<string, string>): Promise<FetchResult<T>> {
    let url = `${BASE_URL}${this.apiPath}`;
    if (queryParams) {
      const params = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      url = `${url}?${params}`;
    }
    const response = await axios.get(url);
    return response.data;
  }

  public async fetchById(id: number, queryParams?: Record<string, string>): Promise<T> {
    const response = await axios.get(`${BASE_URL}${this.apiPath}${id}/`, { params: queryParams });
    return response.data;
  }

  public async fetchByIds(
    ids: number[],
    queryParams?: Record<string, string>
  ): Promise<FetchResult<T>> {
    const response = await axios.get(`${BASE_URL}${this.apiPath}`, {
      params: { ids: JSON.stringify(ids), ...queryParams },
    });
    return response.data;
  }

  public async createByFormData(formData: FormData): Promise<T> {
    const response = await axios.post(`${BASE_URL}${this.apiPath}`, formData);
    return response.data;
  }

  public async updateByFormData(id: number, formData: FormData): Promise<T> {
    const response = await axios.patch(`${BASE_URL}${this.apiPath}${id}/`, formData);
    return response.data;
  }

  public async fetchChildItemsByParentId(
    parentId: number,
    queryParams?: Record<string, string>
  ): Promise<FetchResult<T>> {
    const params = new URLSearchParams(queryParams);
    const response = await axios.get(this.constructUrl(parentId), { params });
    return response.data;
  }

  public async fetchItemByParentIdAndItemId(parentId: number, itemId: number): Promise<T> {
    const response = await axios.get(this.constructUrl(parentId, itemId));
    return response.data;
  }

  public async createItemsForParent(parentId: number, itemIds: number[]): Promise<void> {
    const payload = { ids: itemIds };
    await axios.post(this.constructUrl(parentId), payload);
  }

  public async deleteItemsForParent(parentId: number, itemIds: number[]): Promise<void> {
    const params = new URLSearchParams();
    itemIds.forEach(id => params.append('ids', id.toString()));

    await axios.delete(this.constructUrl(parentId), { params });
  }

  private constructUrl(parentId: number, childId?: number): string {
    if (childId) {
      return `${BASE_URL}${this.apiPath}${parentId}${this.subPath}${childId}/`;
    }
    return `${BASE_URL}${this.apiPath}${parentId}${this.subPath}`;
  }
}
