import { Form } from 'antd';
import * as Component from 'components';
import { playlistService } from 'content/service';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as PlaylistComponent from './components';
import detailHandlers from './handlers/detailHandlers';

const PlaylistEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { handleUpdateButtonClick } = detailHandlers({ form, navigate, id: id as string });
  const [playlistType, setPlaylistType] = useState<string>('');

  useEffect(() => {
    playlistService.fetchById(Number(id)).then(data => {
      setPlaylistType(data.type);
    });
  }, [id]);

  const playlistTabItem =
    playlistType === 'dynamic'
      ? {
          key: 'playlist-rule',
          title: 'Playlist Rule',
          component: <PlaylistComponent.PlaylistRule />,
        }
      : {
          key: 'videos',
          title: 'Videos',
          component: <PlaylistComponent.ContentListComponent />,
        };

  const playlistComponents = [
    {
      key: 'detail',
      title: 'Detail',
      component: (
        <div>
          <PlaylistComponent.DetailComponent
            form={form}
            actionTitle="UPDATE"
            handleOnClickButton={handleUpdateButtonClick}
          />
        </div>
      ),
    },
    playlistTabItem,
  ];

  return (
    <Component.BasePageComponent>
      <Component.TapComponent components={playlistComponents} />
    </Component.BasePageComponent>
  );
};

export default PlaylistEdit;
