import * as Component from 'components';
import React from 'react';
import * as ContentComponent from './components';
import './ContentEdit.css';

const ContentEdit: React.FC = () => {
  const videoComponents = [
    {
      key: 'detail',
      title: 'Detail',
      component: <ContentComponent.DetailComponent />,
    },
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      component: <ContentComponent.ThumbnailComponent />,
    },
    {
      key: 'subtitle',
      title: 'Subtitle',
      component: <ContentComponent.SubtitleComponent />,
    },
    {
      key: 'dubbing',
      title: 'Dubbing',
      component: <ContentComponent.DubbingComponent />,
    },
    {
      key: 'transcode',
      title: 'Transcode',
      component: <ContentComponent.TranscodeComponent />,
    },
    {
      key: 'contract',
      title: 'Contract',
      component: <ContentComponent.ContractComponent />,
    },
    {
      key: 'revenue-share',
      title: 'Revenue Share',
      component: <ContentComponent.RevenueComponent />,
    },
    {
      key: 'service',
      title: 'Service',
      component: <ContentComponent.ServiceComponent />,
    },
  ];

  return (
    <div>
      <Component.BasePageComponent>
        <Component.TapComponent components={videoComponents} />
      </Component.BasePageComponent>
    </div>
  );
};

export default ContentEdit;
