import { TableColumnInterface } from 'components/table/Table';
import { RevenueInterface } from 'content/type';

export const getRevenueColumns = (): TableColumnInterface<RevenueInterface>[] => {
  return [
    {
      title: 'Year/Month',
      dataIndex: 'yearmon',
      key: 'yearmon',
      width: '8%',
    },
    {
      title: 'Content Provider',
      dataIndex: 'content_provider',
      key: 'content_provider',
      width: '10%',
    },
    {
      title: 'Title',
      dataIndex: 'title_ko',
      key: 'title_ko',
      width: '10%',
    },
    {
      title: 'SVOD Revenue',
      dataIndex: 'svod_revenue',
      key: 'svod_revenue',
      width: '8%',
    },
    {
      title: 'SVOD Net Revenue',
      dataIndex: 'svod_net_revenue',
      key: 'svod_net_revenue',
      width: '8%',
    },
    {
      title: 'SVOD VH',
      dataIndex: 'svod_vh',
      key: 'svod_vh',
      width: '8%',
    },
    {
      title: 'SVOD VP',
      dataIndex: 'svod_vp',
      key: 'svod_vp',
      width: '8%',
    },
    {
      title: 'AVOD Revenue',
      dataIndex: 'avod_revenue',
      key: 'avod_revenue',
      width: '8%',
    },
    {
      title: 'AVOD Net Revenue',
      dataIndex: 'net_avod_revenue',
      key: 'net_avod_revenue',
      width: '8%',
    },
    {
      title: 'AVOD VH',
      dataIndex: 'avod_vh',
      key: 'avod_vh',
      width: '8%',
    },
    {
      title: 'AVOD VP',
      dataIndex: 'avod_vp',
      key: 'avod_vp',
      width: '8%',
    },
    {
      title: 'AVOD RS Rate',
      dataIndex: 'avod_rs_rate',
      key: 'avod_rs_rate',
      width: '8%',
    },
    {
      title: 'AVOD X RS Rate',
      dataIndex: 'avod_x_rs_rate',
      key: 'avod_x_rs_rate',
      width: '8%',
    },
    {
      title: 'Total RS Amount',
      dataIndex: 'total_rs_amount',
      key: 'total_rs_amount',
      width: '8%',
    },
  ];
};
