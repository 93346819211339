import { CategoryInterface } from 'content/type';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { categoryService } from '../../service/categoryService';
import { getCategoryColumns } from './CategoryColumns';
import tableHandlers from './handlers/tableHandlers';

export default function Category(): React.JSX.Element {
  const navigate = useNavigate();

  const {
    handleOnClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleAddCategoryClick,
  } = tableHandlers({
    navigate,
  });

  const columns = getCategoryColumns(
    handleOnClickTitle,
    handleOnClickDeleteButton,
    handleOnClickSetting
  );

  const fetchCategoryList = async (
    page: number,
    pageSize: number
  ): Promise<{ data: CategoryInterface[]; count: number }> => {
    const { results, count } = await categoryService.fetchCategoryList(page, pageSize);
    return { data: results, count };
  };

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<CategoryInterface>
        tableName="Category"
        dataFetch={fetchCategoryList}
        columns={columns}
        handleAddClick={handleAddCategoryClick}
      />
    </Component.BasePageComponent>
  );
}
