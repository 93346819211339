import React, { useMemo } from 'react';
import ReactPlayer from 'react-player';

interface PreviewProps {
  videoUrl: string;
}

const Preview: React.FC<PreviewProps> = ({ videoUrl }: PreviewProps) => {
  const player = useMemo(
    () => (
      <ReactPlayer
        url={videoUrl}
        controls
        width="100%"
        height="auto"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
      />
    ),
    [videoUrl]
  );

  return <div className="preview-container">{videoUrl ? player : <p>There is no video...</p>}</div>;
};

export default Preview;
