import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { contractService } from 'content/service';
import { ContractInterface } from 'contract/type';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { ColorButtonComponent } from '../../../../shared/components';
import { getContractColumns } from './ContractColumns';
import { uploadHandlers } from './handlers';
import tableHandlers from './handlers/tableHandlers';

export default function Contract(): React.JSX.Element {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {}, []);

  useEffect(() => {
    setRefreshCounter(prev => prev + 1);
  }, [searchTerm]);

  const fetchContracts = useCallback(
    async (
      page: number,
      pageSize: number
    ): Promise<{ data: ContractInterface[]; count: number }> => {
      const contractList = await contractService.fetch({
        page: page.toString(),
        page_size: pageSize.toString(),
        search: searchTerm,
      });
      return { data: contractList.results, count: contractList.count };
    },
    [searchTerm]
  );

  const { handleOnClickDeleteButton, handleOnClickTitle, handleOnClickSetting, handleAddClick } =
    tableHandlers({
      navigate,
    });

  const { handleFileUpload } = uploadHandlers();

  const columns = getContractColumns({
    handleOnClickTitle,
    handleOnClickSetting,
    handleOnClickDeleteButton,
  });

  const optionalButton: JSX.Element[] = [
    <Upload key="upload" beforeUpload={handleFileUpload} showUploadList={false} accept=".csv">
      <ColorButtonComponent
        color="silver"
        icon={<UploadOutlined />}
        text={`Upload CSV`}
        size={'small'}
      />
    </Upload>,
  ];

  return (
    <Component.BasePageComponent>
      <Component.TableComponent<ContractInterface>
        key={refreshCounter}
        tableName="Contract"
        dataFetch={fetchContracts}
        columns={columns}
        searchTerm={searchTerm}
        handleSearchTerm={setSearchTerm}
        handleAddClick={handleAddClick}
        optionalButton={optionalButton}
      />
    </Component.BasePageComponent>
  );
}
