import { FormInstance, message } from 'antd';
import { playlistContentService } from 'content/service';
import { Key } from 'react';
interface ContentRuleHook {
  handleSave: () => void;
  handleCategorySelect: (checkedKeys: Key[] | { checked: Key[]; halfChecked: Key[] }) => void;
}

interface ContentRuleHandlersProps {
  form: FormInstance;
  playlistId: number;
}
// 타입 정의 추가
type CategoryRule = {
  value_type: 'category';
  value: string;
};

type RecentRule = {
  value_type: 'recent';
  value: string;
};

type SeriesOrSeasonRule = {
  value_type: 'series' | 'season';
  value: string;
};

type Rule = CategoryRule | RecentRule | SeriesOrSeasonRule;
interface FormValues {
  category?: { value: string };
  recent?: number;
  series?: number[];
  season?: number[];
}

const playlistRuleHandlers = ({ form, playlistId }: ContentRuleHandlersProps): ContentRuleHook => {
  const handleSave = async (): Promise<void> => {
    try {
      const values = (await form.validateFields()) as FormValues;
      const rules: Rule[] = [];
      for (const [key, value] of Object.entries(values)) {
        if (!value) continue;

        if (key === 'category') {
          rules.push({
            value: value.join(','),
            value_type: 'category',
          });
        } else if (key === 'recent') {
          rules.push({
            value: String(value),
            value_type: key,
          });
        } else if (key === 'series' || key === 'season') {
          rules.push({
            value: value.join(','),
            value_type: key,
          });
        }
      }

      await playlistContentService.upsertPlaylistRule(playlistId, rules);
      message.success('규칙이 저장되었습니다.');
    } catch (error) {
      message.error('저장 중 오류가 발생했습니다.');
      console.error(error);
    }
  };

  const handleCategorySelect = (
    checkedKeys: Key[] | { checked: Key[]; halfChecked: Key[] }
  ): void => {
    const keys = Array.isArray(checkedKeys) ? checkedKeys : checkedKeys.checked;
    if (keys.includes(0)) {
      // All이 선택된 경우
      const newRules = [0];

      form.setFields([
        {
          name: 'category',
          value: newRules,
        },
      ]);
    } else {
      // 개별 카테고리가 선택된 경우
      const newRules = keys.map(key => Number(key));

      form.setFields([
        {
          name: 'category',
          value: newRules,
        },
      ]);
    }
  };

  return {
    handleSave,
    handleCategorySelect,
  };
};

export default playlistRuleHandlers;
