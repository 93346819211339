import axios from 'axios';
import { BASE_URL, SERIES_API_PATH } from 'constants/api';
import { PosterInterface, UploadPosterInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { SeriesInterface } from '../type/seriesType';

class SeriesService {
  constructor(private apiService: ApiService<SeriesInterface>) {}

  public async fetch(params?: Record<string, string>): Promise<FetchResult<SeriesInterface>> {
    const response = await this.apiService.fetch(params);
    return response;
  }

  public async fetchById(seriesId: number): Promise<SeriesInterface> {
    const response = await this.apiService.fetchById(seriesId);
    return response;
  }

  public async fetchByIds(seriesIds: number[]): Promise<SeriesInterface[]> {
    if (seriesIds.length === 0) {
      return [];
    }
    const response = await this.apiService.fetchByIds(seriesIds);
    return response.results;
  }

  public async create(series: SeriesInterface): Promise<void> {
    await this.apiService.create(series);
  }

  public async update(seriesId: number, series: SeriesInterface): Promise<void> {
    await this.apiService.update(seriesId, series);
  }

  public async uploadPoster(seriesId: number, posters: UploadPosterInterface[]): Promise<void> {
    await Promise.all(
      posters.map(async (poster: UploadPosterInterface) => {
        const formData = new FormData();
        if (poster.file) {
          formData.append('file', poster.file);
        }
        if (poster.is_representative) {
          formData.append('is_representative', poster.is_representative.toString());
        }

        const response = await axios.post(
          `${BASE_URL}${SERIES_API_PATH}${seriesId}/poster/`,
          formData
        );
        return response.data;
      })
    );
  }

  public async removePoster(seriesId: number, posters: PosterInterface[]): Promise<void> {
    await Promise.all(
      posters.map(async (poster: PosterInterface) => {
        await axios.delete(`${BASE_URL}${SERIES_API_PATH}${seriesId}/poster/${poster.id}/`);
      })
    );
  }

  public async updatePoster(seriesId: number, poster: PosterInterface): Promise<void> {
    await axios.patch(`${BASE_URL}${SERIES_API_PATH}${seriesId}/poster/${poster.id}/`, poster);
  }
}

export const seriesService = new SeriesService(new ApiService<SeriesInterface>(SERIES_API_PATH));
