import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

interface SeasonTableData {
  onClickDeleteButton: (id: number) => void;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleAddSeasonClick: () => void;
}

const useSeasonTable = (): SeasonTableData => {
  const navigate = useNavigate();

  const onClickDeleteButton = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/season/edit/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/season/edit/${id}`);
  };

  const handleAddSeasonClick = (): void => {
    navigate('/season/add');
  };

  return {
    onClickDeleteButton: onClickDeleteButton,
    handleOnClickTitle: handleOnClickTitle,
    handleOnClickSetting: handleOnClickSetting,
    handleAddSeasonClick: handleAddSeasonClick,
  };
};

export default useSeasonTable;
