import { contentProviderService } from 'account/services/contentProviderService';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { userService } from '../../services/userService';
import { UserInterface } from '../../type/userType';
import { tableHandlers } from './handlers';
import { getUserColumns } from './UserColumns';

const UserPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [contentProviderList, setContentProviderList] = useState<{ id: number; title: string }[]>(
    []
  );
  const { onClickEditButton, onClickDeleteButton, handleAddUserClick } = tableHandlers({
    navigate,
  });

  const columns = getUserColumns({
    onClickEditButton,
    onClickDeleteButton,
    contentProviderList,
  });

  const fetchUser = async (
    page: number,
    pageSize: number
  ): Promise<{ data: UserInterface[]; count: number }> => {
    const userList = await userService.fetch({
      page: page.toString(),
      page_size: pageSize.toString(),
    });
    return { data: userList.results, count: userList.count };
  };

  useEffect(() => {
    const fetchContentProvider = async (): Promise<void> => {
      const contentProviderList = await contentProviderService.fetchContentProvider();
      const platformProviderList = await contentProviderService.fetchPlatformProvider();
      setContentProviderList([...contentProviderList, ...platformProviderList]);
    };
    fetchContentProvider().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Component.BasePageComponent>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <Component.TableComponent<UserInterface>
          tableName="User"
          columns={columns}
          dataFetch={fetchUser}
          handleAddClick={handleAddUserClick}
        />
      )}
    </Component.BasePageComponent>
  );
};

export default observer(UserPage);
