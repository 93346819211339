import {
  ContentProviderInterface,
  PlatformProviderInterface,
} from 'account/type/contentProviderType';
import { CONTENT_PROVIDER_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

export class ContentProviderService {
  constructor(private apiService: ApiService<ContentProviderInterface>) {}

  public async create(
    contentProvider: ContentProviderInterface
  ): Promise<ContentProviderInterface> {
    return await this.apiService.create(contentProvider);
  }

  public async update(
    id: number,
    contentProvider: ContentProviderInterface
  ): Promise<ContentProviderInterface> {
    return await this.apiService.update(id, contentProvider);
  }

  public async fetch(
    queryParams?: Record<string, string>
  ): Promise<FetchResult<ContentProviderInterface>> {
    return await this.apiService.fetch(queryParams);
  }

  public async fetchById(id: number): Promise<ContentProviderInterface> {
    const response = await this.apiService.fetchById(id);
    return response;
  }

  public async fetchContentProvider(): Promise<ContentProviderInterface[]> {
    const queryParams = {
      page_size: '100',
      type: 'cp',
    };
    const result = await this.apiService.fetch(queryParams);
    return result.results;
  }

  public async fetchPlatformProvider(): Promise<PlatformProviderInterface[]> {
    const queryParams = {
      page_size: '100',
      type: 'pp',
    };
    const result = await this.apiService.fetch(queryParams);
    return result.results;
  }
}

export const contentProviderService = new ContentProviderService(
  new ApiService(CONTENT_PROVIDER_API_PATH)
);
