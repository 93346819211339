import { FormInstance, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { contentService } from 'content/service';
import { SubtitleInterface } from 'content/type';
import React from 'react';

interface SubtitleHandlersProps {
  contentId: number;
  form: FormInstance;
  setDataSource: React.Dispatch<React.SetStateAction<SubtitleInterface[]>>;
}

interface SubtitleHandlers {
  handleLanguageChange: (value: string, record: { id: string }) => void;
  handleFileChange: (info: RcFile, record: { id: string }) => void;
  handleActiveChange: (value: boolean, record: { id: string }) => void;
  handleAddSubtitle: (id: number) => void;
  handleUpdateSubtitle: () => void;
}

const subtitleHandlers = ({
  form,
  contentId,
  setDataSource,
}: SubtitleHandlersProps): SubtitleHandlers => {
  const handleLanguageChange = (value: string, record: { id: string }): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const newDataSource = prev.map(subtitle =>
        subtitle.id === Number(record.id) ? { ...subtitle, lang: value } : subtitle
      );
      form.setFieldValue('subtitleList', newDataSource);
      return newDataSource;
    });
  };

  const handleFileChange = (info: RcFile, record: { id: string }): void => {
    if (info) {
      setDataSource((prev: SubtitleInterface[]) => {
        const newDataSource = prev.map(subtitle =>
          subtitle.id === Number(record.id) ? { ...subtitle, file: info } : subtitle
        );

        form.setFieldValue('subtitleList', newDataSource);
        return newDataSource;
      });
    }
  };

  const handleActiveChange = (value: boolean, record: { id: string }): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const newDataSource = prev.map(subtitle =>
        subtitle.id === Number(record.id) ? { ...subtitle, active: value } : subtitle
      );
      form.setFieldValue('subtitleList', newDataSource);
      return newDataSource;
    });
  };

  const handleAddSubtitle = (id: number): void => {
    setDataSource((prev: SubtitleInterface[]) => {
      const prevNewSubtitle = form.getFieldValue('subtitleList') || [];
      const newSubtitle = {
        id,
        lang: '',
        file: undefined,
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        content_id: contentId,
        external_url: '',
      };
      form.setFieldValue('subtitleList', [...prevNewSubtitle, newSubtitle]);
      return [...prev, newSubtitle];
    });
  };

  const handleUpdateSubtitle = async (): Promise<void> => {
    const loadingMessage = message.loading('Subtitle upload...', 0);
    const subtitleList = form.getFieldValue('subtitleList');

    if (subtitleList) {
      try {
        await contentService.uploadSubtitle(contentId, subtitleList);
      } catch (error) {
        console.log(error);
      }
      message.success('upload success');
    }
    loadingMessage();
    form.resetFields(['subtitleList']);
  };

  return {
    handleLanguageChange,
    handleFileChange,
    handleActiveChange,
    handleAddSubtitle,
    handleUpdateSubtitle,
  };
};

export default subtitleHandlers;
