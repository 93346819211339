import axios from 'axios';
import { BASE_URL, CONTRACT_API_PATH, CONTRACT_UPLOAD_API_PATH } from 'constants/api';
import { ContractInterface } from 'contract/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class ContractService {
  constructor(private apiService: ApiService<ContractInterface>) {}

  public async fetch(params: Record<string, string>): Promise<FetchResult<ContractInterface>> {
    return this.apiService.fetch(params);
  }

  async fetchById(id: number): Promise<ContractInterface> {
    return this.apiService.fetchById(id);
  }

  async create(contract: ContractInterface): Promise<void> {
    await this.apiService.create(contract);
  }

  async update(id: number, contract: ContractInterface): Promise<void> {
    await this.apiService.update(id, contract);
  }

  public async upload(file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${BASE_URL}${CONTRACT_UPLOAD_API_PATH}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }
}

export const contractService = new ContractService(new ApiService(CONTRACT_API_PATH));
