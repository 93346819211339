import { FormInstance, message } from 'antd';
import { playlistService } from 'content/service';
import { NavigateFunction } from 'react-router-dom';

interface DetailHookProps {
  id: string;
  form: FormInstance;
  navigate: NavigateFunction;
}

const detailHandlers = ({
  form,
  id,
  navigate,
}: DetailHookProps): {
  handleUpdateButtonClick: (setIsLoading: (isLoading: boolean) => void) => void;
} => {
  const handleUpdateButtonClick = async (
    setIsLoading: (isLoading: boolean) => void
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const newPlaylist = form.getFieldsValue();
      await playlistService.update(Number(id), newPlaylist);
      setIsLoading(false);
      navigate('/playlists/');
    } catch (error) {
      setIsLoading(false);
      message.error('Failed to update : ' + error);
    }
  };

  return { handleUpdateButtonClick };
};

export default detailHandlers;
