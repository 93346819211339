import { UploadOutlined } from '@ant-design/icons';
import { Col, Form, message, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { formHandlers } from '../handlers';
import { uploadVideoService } from '../service/UploadVideoService';
import { uploadVideoStore } from '../store/uploadVideoStore';
import { UploadVideoInterface } from '../type/uploadVideoType';
import * as UploadComponent from './components';
import './UploadVideo.css';

const UploadVideoPage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { handleChange, handleRemove, handleChangeMetadata } = formHandlers();

  const handleAddButton = async (): Promise<void> => {
    const needUploadVideoList = uploadVideoStore.getUploadVideoList();
    const autoTranscode = form.getFieldValue('autoTranscode');

    if (needUploadVideoList.length === 0) {
      message.warning('No videos selected for upload.');
    } else {
      const updatedListWithProgress = needUploadVideoList.map(
        (videoFile: UploadVideoInterface) => ({
          file: videoFile,
          onSuccess: (): void => {
            message.success(`${videoFile.name} uploaded successfully.`);
          },
          onError: (error: Error): Error => {
            throw error;
          },
          onProgress: (percent: number, speed: number): void => {
            uploadVideoStore.updateProgress(videoFile.uid, percent, speed);
          },
        })
      );

      const uploadResult = await uploadVideoService.uploadVideoFiles(
        updatedListWithProgress,
        autoTranscode
      );

      if (uploadResult) {
        uploadVideoStore.clearUploadVideoList();
        navigate('/library');
      }
    }
  };

  return (
    <Component.BasePageComponent>
      <div className="upload-video-page">
        <div className="upload-header">
          <Component.ColorButtonComponent
            icon={<UploadOutlined />}
            text="Upload"
            onClick={handleAddButton}
          />
        </div>
        <Row gutter={16}>
          <Col span={16}>
            <UploadComponent.UploadVideoFormComponent
              form={form}
              handleChange={handleChange}
              handleRemove={handleRemove}
              onChangeMetadata={handleChangeMetadata}
            />
          </Col>
          <Col span={8}>
            {/* <UploadComponent.SupportedFormatsComponent /> */}
            <UploadComponent.TranscodeProfileComponent />
          </Col>
        </Row>
      </div>
    </Component.BasePageComponent>
  );
};

export default observer(UploadVideoPage);
