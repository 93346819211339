import type { FormInstance } from 'antd';
import { Card, Form, Tag, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import { CategoryInterface } from 'content/type';
import React, { Key } from 'react';

export const CategoryRule: React.FC<{
  handleCategorySelect: (checkedKeys: Key[] | { checked: Key[]; halfChecked: Key[] }) => void;
  categories: CategoryInterface[];
  form: FormInstance;
}> = ({ handleCategorySelect, categories, form }) => {
  const checkedKeys = Form.useWatch('category', form) || [];
  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>([]);
  const treeData: DataNode[] = [
    {
      title: 'all',
      key: 0,
      children: categories.map(category => ({
        title: category.name,
        key: category.id,
        isLeaf: true,
      })),
    },
  ];

  return (
    <div>
      <h3 style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <span>Categories</span>
        <span
          style={{
            flex: 1,
            borderBottom: '1px solid #666',
            marginLeft: '8px',
            height: '0.5em',
          }}
        />
      </h3>
      <Card style={{ marginBottom: '10px', border: '1px solid #ccc' }}>
        {checkedKeys.map((key: string, index: number) => (
          <Tag
            key={index}
            closable
            onClose={() => {
              const newRules = checkedKeys.filter((r: { value: string }) => r.value !== key);
              form.setFields([
                {
                  name: 'category',
                  value: newRules,
                },
              ]);
            }}
          >
            {Number(key) === 0
              ? 'All'
              : categories.find(category => category.id === Number(key))?.name}
          </Tag>
        ))}
      </Card>
      <Form.Item name="category" noStyle>
        <Tree
          checkable
          checkedKeys={checkedKeys}
          treeData={treeData}
          onCheck={handleCategorySelect}
          expandedKeys={expandedKeys}
          onExpand={keys => setExpandedKeys(keys)}
          selectable={false}
          defaultExpandAll
          style={{ width: '100%' }}
        />
      </Form.Item>
    </div>
  );
};
