import { FormInstance } from 'antd';
import { TransferDataHandlerInterface } from 'components/transfer/Transfer';
import { playlistContentService } from 'content/service/playlistContentService';
import { seasonService } from 'content/service/seasonService';
import { SeasonInterface } from 'content/type';

interface SeasonRuleHandler {
  filterOption: (input: string, item: SeasonInterface) => boolean;
  dataHandler: TransferDataHandlerInterface;
}

const seasonRuleHandlers = ({
  id,
  form,
  setRefreshKey,
}: {
  id: string;
  form: FormInstance;
  setRefreshKey: React.Dispatch<React.SetStateAction<number>>;
}): SeasonRuleHandler => {
  const filterOption = (input: string, item: SeasonInterface): boolean =>
    item.title?.includes(input);

  const dataHandler: TransferDataHandlerInterface = {
    fetchSourceData: async (query: Record<string, string>) => {
      const seasons = await seasonService.fetch(query);
      return {
        data: seasons.results.map((season: SeasonInterface) => ({
          ...season,
          key: season.id,
          name: season.title,
          disabled: false,
        })),
        count: seasons.count,
      };
    },
    fetchTargetData: async () => {
      const targetSeasonIds = [];
      const seasonFormData = form.getFieldValue('season');
      if (!seasonFormData || seasonFormData.length === 0) {
        const fetchedRules = await playlistContentService.fetchRules(Number(id));
        const selectedSeasons = fetchedRules.filter(rule => rule.value_type === 'season');
        const selectedSeasonIds =
          selectedSeasons.length > 0
            ? selectedSeasons[0].value.split(',').map(value => Number(value))
            : [];
        targetSeasonIds.push(...selectedSeasonIds);
      } else {
        targetSeasonIds.push(...seasonFormData);
      }
      const seasons = await seasonService.fetchByIds(targetSeasonIds);
      return {
        data: seasons.map((season: SeasonInterface) => ({
          ...season,
          key: season.id,
          name: season.title,
          disabled: false,
        })),
        count: seasons.length,
      };
    },
    createWithParentId: async (ids: number[]) => {
      const seasonIds = form.getFieldValue('season');
      if (!seasonIds) {
        form.setFieldValue('season', ids);
      } else {
        const deduplicatedIds = [...new Set([...seasonIds, ...ids])];
        form.setFieldValue('season', deduplicatedIds);
      }
      setRefreshKey(prev => prev + 1);
    },
    deleteWithParentId: async (ids: number[]) => {
      const seasonIds = form.getFieldValue('season');
      if (!seasonIds) {
        form.setFieldValue('season', []);
      } else {
        const deduplicatedIds = seasonIds.filter((id: number) => !ids.includes(id));
        form.setFieldValue('season', deduplicatedIds);
      }
      setRefreshKey(prev => prev + 1);
    },
  };

  return {
    filterOption,
    dataHandler,
  };
};

export default seasonRuleHandlers;
