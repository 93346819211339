import { TableColumnInterface } from 'components/table/Table';
import React from 'react';
import { VideoSourceVideoListInterface } from '../type';

export const getVideoSourceContentListColumns =
  (): TableColumnInterface<VideoSourceVideoListInterface>[] => {
    return [
      {
        title: 'Thumbnail',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        render: (_text: string, record: VideoSourceVideoListInterface) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{ marginLeft: '10px', width: '100%', height: 'auto' }}
              src={record.content.thumbnail?.file}
              alt=""
            />
          </div>
        ),
      },
      {
        title: 'Guid',
        dataIndex: 'guid',
        key: 'guid',
      },
      {
        title: 'TITLE',
        dataIndex: 'title',
        key: 'title',
        width: '40%',
        render: (_, record): React.ReactNode => {
          return (
            <a
              href={`/video/detail/${record.content.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.content.title}
            </a>
          );
        },
      },
      {
        title: 'duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Original Content Url',
        dataIndex: 'original_content_url',
        key: 'original_content_url',
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'video_source',
        dataIndex: 'video_source',
        key: 'video_source',
      },
      {
        title: 'content',
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: 'created',
        dataIndex: 'created',
        key: 'created',
      },
      {
        title: 'modified',
        dataIndex: 'modified',
        key: 'modified',
      },
    ];
  };
