import { PEOPLE_API_PATH } from 'constants/api';
import { PeopleInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class PeopleService {
  constructor(private apiService: ApiService<PeopleInterface>) {}

  public async fetch(params?: Record<string, string>): Promise<FetchResult<PeopleInterface>> {
    const people = await this.apiService.fetch(params);
    return people;
  }

  public async fetchById(id: number): Promise<PeopleInterface> {
    const people = await this.apiService.fetchById(id);
    return people;
  }

  public async createByFormData(formData: FormData): Promise<void> {
    await this.apiService.createByFormData(formData);
  }

  public async updateByFormData(id: number, formData: FormData): Promise<void> {
    await this.apiService.updateByFormData(id, formData);
  }
}

export const peopleService = new PeopleService(new ApiService<PeopleInterface>(PEOPLE_API_PATH));
