import { FormInstance, message } from 'antd';
import { peopleService } from 'content/service';
import { NavigateFunction } from 'react-router-dom';

interface DetailHandlersProps {
  navigate: NavigateFunction;
  form: FormInstance;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleOnClickDeleteButtonClick: (id: number) => Promise<void>;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const detailHandlers = ({ form, navigate }: DetailHandlersProps): DetailHandlers => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const loadingMessage = message.loading('Creating...', 0);
    try {
      const formData = new FormData();
      formData.append('name', form.getFieldValue('name'));
      if (form.getFieldValue('name_en')) {
        formData.append('name_en', form.getFieldValue('name_en'));
      }
      if (form.getFieldValue('birthdate')) {
        formData.append('birthdate', form.getFieldValue('birthdate'));
      }
      if (form.getFieldValue('nationality')) {
        formData.append('nationality', form.getFieldValue('nationality'));
      }
      if (form.getFieldValue('thumbnail')) {
        formData.append('thumbnail', form.getFieldValue('thumbnail'));
      }
      if (form.getFieldValue('type')) {
        formData.append('type', form.getFieldValue('type'));
      }

      await peopleService.createByFormData(formData);
      loadingMessage();
      navigate('/people');
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  const handleOnClickEditButtonClick = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    try {
      const formData = new FormData();
      formData.append('name', form.getFieldValue('name'));
      if (form.getFieldValue('name_en')) {
        formData.append('name_en', form.getFieldValue('name_en'));
      }
      if (form.getFieldValue('birthdate')) {
        formData.append('birthdate', form.getFieldValue('birthdate'));
      }
      if (form.getFieldValue('nationality')) {
        formData.append('nationality', form.getFieldValue('nationality'));
      }
      if (form.getFieldValue('thumbnail')) {
        formData.append('thumbnail', form.getFieldValue('thumbnail'));
      }
      if (form.getFieldValue('type')) {
        formData.append('type', form.getFieldValue('type'));
      }

      await peopleService.updateByFormData(form.getFieldValue('id'), formData);
      loadingMessage();
      navigate('/people');
    } catch (error) {
      loadingMessage();
      message.error('Failed to update : ' + error);
    }
  };

  const handleOnClickDeleteButtonClick = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.[0]) {
      form.setFieldsValue({ thumbnail: e.target.files[0] });
    }
  };

  return {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleOnClickDeleteButtonClick,
    handleOnChange,
  };
};

export default detailHandlers;
