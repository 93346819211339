import { message } from 'antd';
import { ContentInterface } from 'content/type';
import { NavigateFunction } from 'react-router-dom';

interface ContentHandlersProps {
  navigate: NavigateFunction;
  setContentList?: (contentList: ContentInterface[]) => void;
  setIsModalVisible?: (isVisible: boolean) => void;
  setSelectedRecord?: (record: ContentInterface | null) => void;
}

interface ContentHandlers {
  onClickDeleteButton: (id: number) => Promise<void>;
  handleAddVideoClick: () => void;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleShowModal: (record: ContentInterface) => void;
  handleCancelModal: () => void;
  handleBulkDelete: (ids: number[]) => Promise<void>;
}

const contentHandlers = ({
  navigate,
  setIsModalVisible = (): void => {},
  setSelectedRecord = (): void => {},
}: ContentHandlersProps): ContentHandlers => {
  const onClickDeleteButton = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  // My Local Files 클릭 시 이동 처리
  const handleAddVideoClick = (): void => {
    navigate('/upload/video');
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/video/detail/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/video/detail/${id}`);
  };

  const handleShowModal = (record: ContentInterface): void => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleCancelModal = (): void => {
    setIsModalVisible(false);
  };

  const handleBulkDelete = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  return {
    onClickDeleteButton,
    handleAddVideoClick,
    handleOnClickTitle,
    handleOnClickSetting,
    handleShowModal,
    handleCancelModal,
    handleBulkDelete,
  };
};

export default contentHandlers;
