import { Button, Checkbox, DatePicker, Form, FormInstance, Input, message, Switch } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { videoSourceService } from 'upload/video-source/service/videoSourceService';
import * as Component from '../../../../../../shared/components';
import { detailHandlers } from '../../../handlers';
import { default as VideoSourceModal } from '../../../page/components/modal/Modal';
import { ImportVideoDataInterface, VideoSourceInterface } from '../../../type/videoSourceType';

interface VideoSourceDetailProps {
  form: FormInstance;
  isODX?: boolean;
  showRefreshButton?: boolean;
  videoSource: VideoSourceInterface;
  handleOnClickButton: () => void;
}

const VideoSourceDetail: React.FC<VideoSourceDetailProps> = ({
  form,
  isODX,
  showRefreshButton,
  videoSource,
  handleOnClickButton,
}) => {
  // const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [importVideoDataList, setImportVideoDataList] = useState<ImportVideoDataInterface[]>([]);
  const [startFromBeginning, setStartFromBeginning] = useState(false);

  const { handleOnClickPreviewButton, handleCloseModal } = detailHandlers({
    setLoading,
    setModalTitle,
    setModalVisible,
    setImportVideoDataList,
  });

  const handleOnClickForceRefresh = async (): Promise<void> => {
    setLoading(true);
    try {
      await videoSourceService.generate(videoSource.id);
      message.success('Force refresh success');
    } catch (error) {
      message.error(`Force refresh failed ${error}`);
    }
    setLoading(false);
  };

  const handleOnClickBulkRefresh = async (): Promise<void> => {
    setLoading(true);
    try {
      await videoSourceService.bulkOdkSync(startFromBeginning);
      message.success('Bulk refresh success');
    } catch (error) {
      message.error(`Bulk refresh failed ${error}`);
    }
    setLoading(false);
  };

  return (
    <div className="video-source-details-page">
      <h1>Edit Video Source: {videoSource && videoSource.name}</h1>
      {showRefreshButton && (
        <>
          <div style={{ padding: '20px' }}>
            <Button
              color="default"
              variant="solid"
              onClick={handleOnClickForceRefresh}
              disabled={loading}
            >
              Force Refresh
            </Button>
            <div>Last synced: {videoSource?.last_synced}</div>
          </div>
          <div style={{ padding: '20px', display: 'none' }}>
            <Button color="danger" variant="solid" onClick={handleOnClickBulkRefresh}>
              Bulk Full Refresh
            </Button>
            <Checkbox
              style={{ marginLeft: '20px' }}
              onChange={e => setStartFromBeginning(e.target.checked)}
            >
              start from beginning
            </Checkbox>
          </div>
        </>
      )}
      <Form
        form={form}
        layout="vertical"
        className="video-source-details-form"
        initialValues={videoSource}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter a name!' }]}
        >
          <Input readOnly={isODX} />
        </Form.Item>
        <Form.Item
          label="MRSS URL"
          name="mrss_url"
          rules={[{ required: true, message: 'Please enter MRSS URL!' }]}
        >
          <Input readOnly={isODX} />
        </Form.Item>
        {!isODX && (
          <Button
            style={{ marginBottom: '20px' }}
            onClick={() =>
              handleOnClickPreviewButton(form.getFieldValue('mrss_url'), form.getFieldValue('name'))
            }
          >
            preview mrsss source
          </Button>
        )}
        <VideoSourceModal
          modalTitle={modalTitle}
          videoListModalOpen={modalVisible}
          videoListModalOnCancel={handleCloseModal}
          loading={loading}
          importVideoDataList={importVideoDataList}
        />
        <Form.Item label="Auto Add Videos" name="auto_add_videos" valuePropName="checked">
          <Switch disabled={isODX} />
          {/* 실제 file을 s3에 올릴것인가 */}
        </Form.Item>
        <Form.Item label="Auto Activate Videos" name="auto_activate_videos" valuePropName="checked">
          {/* 가져온 content active 상태 */}
          <Switch disabled={isODX} />
        </Form.Item>
        {!isODX && (
          <>
            <h3>추후 제공 예정</h3>
            <Form.Item label="Category" name="category">
              <Input readOnly={isODX} disabled={true} />
            </Form.Item>
            <Form.Item label="Category Values" name="category_values">
              <Input readOnly={isODX} disabled={true} />
            </Form.Item>
            <Form.Item label="Import Start Date" name="import_from">
              <DatePicker disabled={true} />
            </Form.Item>
            <Form.Item label="Import End Date" name="import_to">
              <DatePicker disabled={true} />
            </Form.Item>
          </>
        )}
        {/* <Form.Item label="Auto Update Videos" name="auto_update_videos" valuePropName="checked">
          <Switch />
        </Form.Item> */}
        {/* <Form.Item
          label="Auto Update Custom Thumbnails"
          name="auto_update_custom_thumbnails"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Auto Update Video Data Sources"
          name="auto_update_video_data_sources"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Auto Deactivate Video Data Sources"
          name="auto_deactivate_video_data_sources"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item> */}
        {/* <Form.Item label="Sync Interval" name="sync_interval">
          <Input readOnly={readonly} />
        </Form.Item> */}
        {/* <Form.Item label="Headers" name="headers">
          <Input.TextArea />
        </Form.Item> */}
        {/* <Form.Item label="Transcode Profile" name="transcode_profile">
          <Input readOnly={readonly} />
        </Form.Item> */}
        {/* <Form.Item label="Active" name="active" valuePropName="checked">
          <Switch disabled={readonly} />
        </Form.Item> */}
        {/* <Form.Item label="Import Setting" name="import_setting">
          <Select>
            <Option value="metadata_only">Metadata Only</Option>
            <Option value="full">Full</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Videos" name="videos">
          <Input.TextArea />
        </Form.Item> */}

        {!isODX && (
          <Component.ColorButtonComponent
            text={videoSource ? 'Save' : 'Add'}
            onClick={handleOnClickButton}
          />
        )}
      </Form>
    </div>
  );
};

export default observer(VideoSourceDetail);
