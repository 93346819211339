import { Form } from 'antd';
import { fetchSeriesDetailData } from 'content/page/series/handlers/fetchDataHandlers';
import { seasonService, seriesService } from 'content/service';
import { CategoryInterface } from 'content/type';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Component from '../../../../shared/components';
import { SeriesInterface } from '../../type/seriesType';
import * as SeriesComponent from './components';

const SeriesEdit: React.FC = () => {
  const [form] = Form.useForm();
  const [series, setSeries] = useState<SeriesInterface | undefined>();
  const { id } = useParams();
  const [, setIsLoading] = useState<boolean>(true);
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>([]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { fetchedSeries, fetchedCategories } = await fetchSeriesDetailData(Number(id));

      setSeries(fetchedSeries);
      setCategoryList(fetchedCategories);
    };
    fetchData().then(() => {
      setIsLoading(false);
    });

    const fetchSeries = async (): Promise<void> => {
      const series = await seriesService.fetchById(Number(id));
      if (series) {
        setSeries(series);
        const formattedSeries = {
          ...series,
          release_date_start: series.release_date_start,
          release_date_end: series.release_date_end,
          produce_country: series.produce_country,
        };

        const seasons = await seasonService.fetchByIds(series.seasons || []);
        form.setFieldsValue(formattedSeries);
        form.setFieldValue(
          'seasons',
          seasons.map(season => {
            return { ...season, name: season.title };
          }) || []
        );

        // form.setFieldsValue(formattedSeries);
      }
    };
    fetchSeries().then(() => {
      setIsLoading(false);
    });
  }, [id, form]);

  const components = [
    {
      key: 'detail',
      title: 'Detail',
      component: (
        <SeriesComponent.SeriesDetailComponent
          form={form}
          series={series || undefined}
          contentCategoryList={categoryList || undefined}
          actionTitle="SAVE"
          // handleOnClickButtonClick={handleOnClickEditButtonClick}
        />
      ),
    },
    {
      key: 'poster',
      title: 'Poster',
      component: (
        <SeriesComponent.PosterComponent
          seriesId={Number(id)}
          uploadedPosterList={series?.posters || []}
        />
      ),
    },
    {
      key: 'season',
      title: 'Season',
      component: <SeriesComponent.SeasonListComponent seriesId={Number(id)} />,
    },
  ];

  return (
    <Component.BasePageComponent>
      <Component.TapComponent components={components} />
    </Component.BasePageComponent>
  );
};

export default observer(SeriesEdit);
