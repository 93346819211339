import { FormInstance, message } from 'antd';
import { categoryService } from 'content/service/categoryService';
import { NavigateFunction } from 'react-router-dom';

interface DetailHandlersProps {
  id: string;
  form: FormInstance;
  navigate: NavigateFunction;
}

interface DetailHandlers {
  handleOnClickAddButtonClick: () => Promise<void>;
  handleOnClickEditButtonClick: () => Promise<void>;
  handleOnClickDeleteButtonClick: (id: number) => Promise<void>;
}

const detailHandlers = ({ id, form, navigate }: DetailHandlersProps): DetailHandlers => {
  const handleOnClickAddButtonClick = async (): Promise<void> => {
    const loadingMessage = message.loading('Creating...', 0);
    try {
      await categoryService.create(form);
      loadingMessage();
      navigate('/category');
    } catch (error) {
      loadingMessage();
      message.error('Failed to create : ' + error);
    }
  };

  const handleOnClickEditButtonClick = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    try {
      await categoryService.update(Number(id), form);
      loadingMessage();
      navigate('/category');
    } catch (error) {
      loadingMessage();
      message.error('Failed to update : ' + error);
    }
  };

  const handleOnClickDeleteButtonClick = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  return {
    handleOnClickAddButtonClick,
    handleOnClickEditButtonClick,
    handleOnClickDeleteButtonClick,
  };
};

export default detailHandlers;
