import axios from 'axios';
import { BASE_URL, SEASON_API_PATH } from 'constants/api';
import { PosterInterface, SeasonInterface, UploadPosterInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class SeasonService {
  constructor(private apiService: ApiService<SeasonInterface>) {}

  public async fetch(query?: Record<string, string>): Promise<FetchResult<SeasonInterface>> {
    return this.apiService.fetch(query);
  }

  public async fetchById(seasonId: number): Promise<SeasonInterface> {
    const response = await this.apiService.fetchById(seasonId);
    return response;
  }

  public async fetchByIds(seasonIds: number[]): Promise<SeasonInterface[]> {
    if (seasonIds.length === 0) {
      return [];
    }
    const response = await this.apiService.fetchByIds(seasonIds);
    return response.results;
  }

  public async create(season: SeasonInterface): Promise<void> {
    await this.apiService.create(season);
  }

  public async update(seasonId: number, season: SeasonInterface): Promise<void> {
    await this.apiService.update(seasonId, season);
  }

  public async fetchPoster(seasonId: number): Promise<PosterInterface[]> {
    const response = await axios.get(`${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/`);
    return response.data;
  }

  public async uploadPoster(seasonId: number, posters: UploadPosterInterface[]): Promise<void> {
    await Promise.all(
      posters.map(async (poster: UploadPosterInterface) => {
        const formData = new FormData();
        if (poster.file) {
          formData.append('file', poster.file);
        }
        if (poster.is_representative) {
          formData.append('is_representative', poster.is_representative.toString());
        }
        const response = await axios.post(
          `${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/`,
          formData
        );
        return response.data;
      })
    );
  }

  public async removePoster(seasonId: number, posters: PosterInterface[]): Promise<void> {
    await Promise.all(
      posters.map(async (poster: PosterInterface) => {
        await axios.delete(`${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/${poster.id}/`);
      })
    );
  }

  public async updatePoster(seasonId: number, poster: PosterInterface): Promise<void> {
    await axios.patch(`${BASE_URL}${SEASON_API_PATH}${seasonId}/poster/${poster.id}/`, poster);
  }
}

export const seasonService = new SeasonService(new ApiService<SeasonInterface>(SEASON_API_PATH));
