import { UserInterface } from 'account/type/userType';
import { Button, Form, FormInstance, Input, Select } from 'antd';
import React from 'react';

const { Option } = Select;

interface UserDetailProps {
  form: FormInstance;
  user?: UserInterface;
  contentProviderList: { id: number; title: string }[];
  actionTitle: string;
  onSubmit: () => void;
  readOnly?: boolean;
}

const UserDetail: React.FC<UserDetailProps> = ({
  form,
  user,
  contentProviderList,
  actionTitle,
  onSubmit,
  readOnly = false,
}) => {
  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <Form.Item
        name="username"
        label="ID"
        rules={[{ required: true, message: 'Please enter a username' }]}
      >
        <Input readOnly={readOnly} />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        initialValue={user?.email}
        rules={[
          { required: true, message: 'Please enter an email' },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="first_name"
        label="First Name"
        initialValue={user?.first_name}
        rules={[{ required: true, message: 'Please enter a first name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        initialValue={user?.last_name}
        rules={[{ required: true, message: 'Please enter a last name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="project_id"
        label="Organization"
        initialValue={user?.project_id}
        rules={[
          { required: true, message: 'Please enter a project' },
          { pattern: /^\d+$/, message: 'Please enter only numbers' },
        ]}
      >
        <Select>
          {contentProviderList.map(contentProvider => (
            <Option value={contentProvider.id} key={contentProvider.id}>
              {contentProvider.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="role"
        label="Role"
        initialValue={user?.role}
        rules={[{ required: true, message: 'Please select a role' }]}
      >
        <Select placeholder="Select role">
          <Option value="admin">admin</Option>
          <Option value="operator">operator</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {actionTitle}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserDetail;
