import { FormInstance, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { contentService } from 'content/service';
import { DubbingInterface } from 'content/type';
import React from 'react';

interface DubbingHandlersProps {
  form: FormInstance;
  contentId: number;
  setDataSource: React.Dispatch<React.SetStateAction<DubbingInterface[]>>;
}

interface DubbingHandlers {
  handleLanguageChange: (value: string, record: { id: string }) => void;
  handleFileChange: (info: RcFile, record: { id: string }) => void;
  handleAddDubbing: (id: number) => void;
  handleUpdateDubbing: () => void;
}

const dubbingHandlers = ({
  form,
  contentId,
  setDataSource,
}: DubbingHandlersProps): DubbingHandlers => {
  const handleLanguageChange = (value: string, record: { id: string }): void => {
    setDataSource((prev: DubbingInterface[]) => {
      const newDubbing = prev.map(dubbing =>
        dubbing.id === Number(record.id) ? { ...dubbing, lang: value } : dubbing
      );
      form.setFieldValue('dubbingList', newDubbing);
      return newDubbing;
    });
  };

  const handleFileChange = (info: RcFile, record: { id: string }): void => {
    if (info) {
      setDataSource((prev: DubbingInterface[]) => {
        const newDubbing = prev.map(dubbing =>
          dubbing.id === Number(record.id) ? { ...dubbing, file: info } : dubbing
        );
        form.setFieldValue('dubbingList', newDubbing);
        return newDubbing;
      });
    }
  };

  const handleAddDubbing = (id: number): void => {
    setDataSource(prev => {
      const prevNewDubbing = form.getFieldValue('dubbingList') || [];
      const newDubbing = {
        id,
        lang: '',
        file: undefined,
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        content_id: contentId,
        external_url: '',
      };
      form.setFieldValue('dubbingList', [...prevNewDubbing, newDubbing]);
      return [...prev, newDubbing];
    });
  };

  const handleUpdateDubbing = async (): Promise<void> => {
    const dubbingList = form.getFieldValue('dubbingList') || [];
    const loadingMessage = message.loading('Updating...', 0);

    if (dubbingList) {
      try {
        await contentService.uploadDubbing(contentId, dubbingList);
        message.success('upload success');
      } catch (error) {
        console.log(error);
      }
    }
    loadingMessage();
    form.resetFields(['subtitleList']);
  };

  return {
    handleUpdateDubbing,
    handleLanguageChange,
    handleFileChange,
    handleAddDubbing,
  };
};

export default dubbingHandlers;
