import { Form, FormInstance, Input, Select, Typography } from 'antd';
import * as Component from 'components';
import { BASE_URL } from 'constants/api';
import { playlistService } from 'content/service';
import { PlaylistInterface } from 'content/type/playlistType';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Detail.css';

interface PlaylistDetailProps {
  form: FormInstance;
  actionTitle: string;
  handleOnClickButton: (setIsLoading: (isLoading: boolean) => void) => void;
}

const { Text, Link } = Typography;
const MRSS_URL_FORMAT = `${BASE_URL}/api/v1/playlist/{id}/mrss/{service_name}`;

const PlaylistDetail: React.FC<PlaylistDetailProps> = ({
  form,
  actionTitle,
  handleOnClickButton,
}) => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [playlistData, setPlaylistData] = useState<PlaylistInterface>();

  const fetchPlaylist = async (): Promise<void> => {
    const playlist = await playlistService.fetchById(Number(id));
    setPlaylistData(playlist);
    form.setFieldsValue(playlist);
    setIsLoading(false);
  };

  useEffect(() => {
    if (actionTitle === 'UPDATE') {
      fetchPlaylist().then();
    }
  }, [id, form]);

  return (
    <>
      {isLoading ? (
        <Component.LoadingSpinnerComponent />
      ) : (
        <div>
          <div className="playlist-details-page">
            <Form
              form={form}
              layout="vertical"
              className="playlist-details-form"
              initialValues={playlistData}
            >
              <Form.Item name="title" label="Title">
                <Input />
              </Form.Item>
              <Form.Item name="description" label="Description">
                <Input />
              </Form.Item>
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Select
                  options={[
                    { value: 'manual', label: 'manual' },
                    { value: 'dynamic', label: 'dynamic' },
                  ]}
                  disabled={actionTitle === 'UPDATE'}
                />
              </Form.Item>
              <Form.Item name="created" label="Created">
                <Input readOnly />
              </Form.Item>
            </Form>
          </div>
          {actionTitle === 'UPDATE' ? (
            <div>
              <div className="playlist-sources">
                <h3>Playlist MRSS Sources</h3>
              </div>
              <div className="playlist-mrss" style={{ marginTop: '30px' }}>
                <Text strong style={{ marginLeft: '30px', marginRight: '10px' }}>
                  Zype MRSS Feed URL:
                </Text>
                <Link
                  href={MRSS_URL_FORMAT.replace('{id}', String(id)).replace(
                    '{service_name}',
                    'zype'
                  )}
                  target="_blank"
                >
                  {MRSS_URL_FORMAT.replace('{id}', String(id)).replace('{service_name}', 'zype')}
                </Link>
                <br />
                <Text strong style={{ marginLeft: '30px', marginRight: '10px' }}>
                  Amagi MRSS Feed URL:
                </Text>
                <Link
                  href={MRSS_URL_FORMAT.replace('{id}', String(id)).replace(
                    '{service_name}',
                    'amagi'
                  )}
                  target="_blank"
                >
                  {MRSS_URL_FORMAT.replace('{id}', String(id)).replace('{service_name}', 'amagi')}
                </Link>
              </div>
            </div>
          ) : null}
          <div className="playlist-detail-update-button" style={{ marginTop: '30px' }}>
            <Component.ColorButtonComponent
              text={actionTitle}
              onClick={() => handleOnClickButton(setIsLoading)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PlaylistDetail;
