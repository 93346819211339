import { contentProviderService } from 'account/services/contentProviderService';
import { FilterValue } from 'antd/es/table/interface';
import { RevenueInterface } from 'content/type/revenueType';
import React, { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { revenueService } from 'src/domain/revenue/services';

type RevenuePieChartProps = {
  filters: Record<string, FilterValue | null>;
  selectedRow: string | null;
};

export const RevenuePieChart: React.FC<RevenuePieChartProps> = ({
  filters,
  selectedRow,
}: RevenuePieChartProps) => {
  // const [pieChartData, setPieChartData] = useState<RevenueInterface[]>([]);
  const [filteredPieChartData, setFilteredPieChartData] = useState<RevenueInterface[]>([]);
  const [hiddenSeries, setHiddenSeries] = useState<Set<string>>(new Set());

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  const transformData = (data: RevenueInterface[]): { name: string; value: number }[] => {
    if (!data.length) return [];

    const item = data[0];
    return [
      { name: 'AVOD', value: parseFloat(item.avod_rev) },
      { name: 'Digital Distribution', value: parseFloat(item.digital_distribution_rev) },
      { name: 'FAST', value: parseFloat(item.fast_rev) },
      { name: 'Media', value: parseFloat(item.media_rev) },
      { name: 'PPV', value: parseFloat(item.ppv_rev) },
      { name: 'Product IP', value: parseFloat(item.product_ip_rev) },
      { name: 'SVOD', value: parseFloat(item.svod_rev) },
      { name: 'Theatrical', value: parseFloat(item.theatrical_rev) },
    ].filter(item => item.value > 0); // 값이 0인 항목 제외
  };

  const pieData = React.useMemo(() => transformData(filteredPieChartData), [filteredPieChartData]);

  const CustomTooltip = React.useCallback(
    ({ active, payload }: TooltipProps<ValueType, NameType>): React.ReactNode => {
      if (active && payload && payload.length) {
        const transformedData = transformData(filteredPieChartData);
        const total = transformedData.reduce((sum, item) => sum + item.value, 0);
        const percent = (((payload[0].value as number) / total) * 100).toFixed(1);
        return (
          <div style={{ backgroundColor: 'white', padding: '5px 10px', border: '1px solid #ccc' }}>
            <p>{`${payload[0].name}: ${Number(payload[0].value).toLocaleString()} (${percent}%)`}</p>
          </div>
        );
      }
      return null;
    },
    [pieData]
  );

  const renderCustomizedLabel = React.useCallback(
    ({
      cx,
      cy,
      midAngle,
      outerRadius,
      percent,
      name,
    }: {
      cx: number;
      cy: number;
      midAngle: number;
      innerRadius: number;
      outerRadius: number;
      percent: number;
      name: string;
    }): React.ReactNode => {
      const RADIAN = Math.PI / 180;
      const radius = outerRadius + 30;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return (
        <text
          x={x}
          y={y}
          fill="#000"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {!hiddenSeries.has(name) ? `${name} (${(percent * 100).toFixed(1)}%)` : ''}
        </text>
      );
    },
    [hiddenSeries]
  );

  const handleLegendClick = (entry: { value: string }): void => {
    setHiddenSeries(prev => {
      const newHidden = new Set(prev);
      if (newHidden.has(entry.value)) {
        newHidden.delete(entry.value);
      } else {
        newHidden.add(entry.value);
      }
      return newHidden;
    });
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const groupBy = filters.group_by?.[0] === 'content_provider' ? 'content_provider' : 'yearmon';
      const [contentProvider, rowTitle, yearmon] = selectedRow?.split(':-') ?? [];
      const cpList = await contentProviderService.fetch();
      const cpId = cpList.results.find(cp => cp.title === contentProvider)?.id;
      const queryParam: Record<string, string> = {
        content_id: String(filters.content_id?.[0] ?? ''),
        title: rowTitle === 'null' ? '' : String(rowTitle ?? filters.title?.[0] ?? ''),
        start_yearmon:
          yearmon === 'null' ? '' : String(yearmon ?? filters.start_yearmon?.[0] ?? ''),
        end_yearmon: yearmon === 'null' ? '' : String(yearmon ?? filters.end_yearmon?.[0] ?? ''),
        content_provider_id: String(cpId ?? filters.content_provider_id?.[0] ?? ''),
        group_by: selectedRow ? 'yearmon' : String(groupBy),
        order_by: String(filters.order_by?.[0] ?? ''),
        order: String(filters.order?.[0] ?? ''),
        paging: String(filters.paging?.[0] ?? ''),
      };
      Object.keys(queryParam).forEach(key => {
        if (!queryParam[key]) {
          delete queryParam[key];
        }
      });
      const data = (await revenueService.fetch(queryParam)).results;
      setFilteredPieChartData(data);
    };
    fetchData();
  }, [filters, selectedRow]);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <PieChart width={1000} height={520}>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={200}
          fill="#8884d8"
          label={renderCustomizedLabel}
        >
          {transformData(filteredPieChartData).map((entry, index) => (
            <Cell
              key={`cell-${entry.name}`}
              fill={COLORS[index % COLORS.length]}
              opacity={hiddenSeries.has(entry.name) ? 0.2 : 1}
            />
          ))}
        </Pie>
        <Tooltip content={CustomTooltip} />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          iconType="circle"
          onClick={handleLegendClick}
        />
      </PieChart>
    </div>
  );
};
