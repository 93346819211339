import { CONTENT_SUB_PATH, CONTRACT_API_PATH } from 'constants/api';
import { ContentInterface } from 'content/type';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';

class ContractContentService {
  constructor(private apiService: ApiService<ContentInterface>) {}

  public async fetchChildItemsByParentId(
    parentId: number,
    params: Record<string, string>
  ): Promise<FetchResult<ContentInterface>> {
    return await this.apiService.fetchChildItemsByParentId(parentId, params);
  }

  public async createItemsForParent(parentId: number, ids: number[]): Promise<void> {
    return await this.apiService.createItemsForParent(parentId, ids);
  }

  public async deleteItemsForParent(parentId: number, ids: number[]): Promise<void> {
    return await this.apiService.deleteItemsForParent(parentId, ids);
  }
}

export const contractContentService = new ContractContentService(
  new ApiService(CONTRACT_API_PATH, CONTENT_SUB_PATH)
);
