import { FormInstance } from 'antd/es/form';
import { FilterValue } from 'antd/es/table/interface';

export const getRevenueFilterHandler = (
  form: FormInstance,
  setFilters: (filters: Record<string, FilterValue | null>) => void,
  setSelectedRow: (row: string | null) => void
): {
  handleFilterSubmit: (values: Record<string, FilterValue | null>) => void;
  handleReset: () => void;
} => {
  const handleFilterSubmit = (values: Record<string, FilterValue | null>): void => {
    const newFilters: Record<string, FilterValue | null> = {};

    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        newFilters[key] = Array.isArray(value) ? value : [value];
      }
    });

    setFilters(newFilters); // 테이블 필터 상태 업데이트
  };

  // 필터 초기화 함수
  const handleReset = (): void => {
    form.resetFields();
    setFilters({});
    setSelectedRow(null);
  };

  return {
    handleFilterSubmit,
    handleReset,
  };
};
