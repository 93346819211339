import { message } from 'antd';
import { NavigateFunction } from 'react-router-dom';

interface TableHandlersProps {
  navigate: NavigateFunction;
}

interface TableHandlers {
  handleOnClickDeleteButton: (id: number) => void;
  handleOnClickTitle: (id: number) => void;
  handleOnClickSetting: (id: number) => void;
  handleAddClick: () => void;
}

export default function tableHandlers({ navigate }: TableHandlersProps): TableHandlers {
  const handleOnClickDeleteButton = async (): Promise<void> => {
    message.info('You do not have permission');
    return;
  };

  const handleOnClickTitle = (id: number): void => {
    navigate(`/people/edit/${id}`);
  };

  const handleOnClickSetting = (id: number): void => {
    navigate(`/people/edit/${id}`);
  };

  const handleAddClick = (): void => {
    navigate('/people/add');
  };

  return {
    handleOnClickDeleteButton,
    handleOnClickTitle,
    handleOnClickSetting,
    handleAddClick,
  };
}
