import { ColumnGroupType, ColumnType, FilterValue, Key } from 'antd/es/table/interface';
import { RevenueInterface } from 'content/type/revenueType';
import React from 'react';

export const useRevenueDashboardColumns = (
  filters: Record<string, FilterValue | null>
): (ColumnType<RevenueInterface> | ColumnGroupType<RevenueInterface>)[] => {
  return [
    filters.group_by?.[0] === 'content_provider' || filters.group_by?.[0] === 'content_yearmon'
      ? {
          title: (): React.ReactNode => <span>Content Provider</span>,
          dataIndex: 'content_provider',
          key: 'content_provider',
          onFilter: (value: boolean | Key, record: RevenueInterface): boolean =>
            record.content_provider === value,
          sorter: true,
        }
      : undefined,
    filters.group_by?.[0] === 'content_provider_content' ||
    filters.group_by?.[0] === 'content_yearmon'
      ? {
          title: (): React.ReactNode => <span>Title (EN)</span>,
          dataIndex: 'title_en',
          key: 'title_en',
          onFilter: (value: boolean | Key, record: RevenueInterface): boolean =>
            record.title_en === value,
          sorter: true,
        }
      : undefined,
    filters.group_by?.[0] === 'yearmon' || filters.group_by?.[0] === 'content_yearmon'
      ? {
          title: 'Year Month',
          dataIndex: 'yearmon',
          key: 'yearmon',
          onFilter: (value: boolean | Key, record: RevenueInterface): boolean =>
            record.yearmon === value,
          sorter: true,
        }
      : undefined,
    { title: 'AVOD', dataIndex: 'avod_rev', key: 'avod_rev' },
    { title: 'SVOD', dataIndex: 'svod_rev', key: 'svod_rev' },
    { title: 'PPV', dataIndex: 'ppv_rev', key: 'ppv_rev' },
    {
      title: 'Digital Distribution',
      dataIndex: 'digital_distribution_rev',
      key: 'digital_distribution_rev',
    },
    { title: 'Theatrical', dataIndex: 'theatrical_rev', key: 'theatrical_rev' },
    { title: 'FAST', dataIndex: 'fast_rev', key: 'fast_rev' },
    { title: 'Media', dataIndex: 'media_rev', key: 'media_rev' },
    { title: 'Product & IP', dataIndex: 'product_ip_rev', key: 'product_ip_rev' },
    { title: 'Total', dataIndex: 'total_rev', key: 'total_rev' },
  ].filter(Boolean) as (ColumnType<RevenueInterface> | ColumnGroupType<RevenueInterface>)[];
};
