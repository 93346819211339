import { Form, Input } from 'antd';
import { contentService } from 'content/service/contentService';
import { ContentInterface } from 'content/type';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Service.css';

export const ServiceDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [content, setContent] = useState<ContentInterface | undefined>();
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const content = await contentService.fetchById(Number(id));
      form.setFieldsValue(content);
      setContent(content);
    };
    fetchData();
  }, [form, id]);

  return (
    <div>
      <div className="content-details-page" style={{ display: 'flex' }}>
        <div className="content-details-edit">
          <h1>{content && content.title}</h1>
          <Form
            form={form}
            layout="vertical"
            className="content-details-form"
            initialValues={content}
          >
            {content?.odk_meta ? (
              <>
                <h2>distributed service: {content.odk_meta.service_name} </h2>
                <Form.Item label="service name" name={['odk_meta', 'service_name']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="episode id" name={['odk_meta', 'episode_id']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="uuid" name={['odk_meta', 'uuid']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="slug" name={['odk_meta', 'slug']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="episode kind" name={['odk_meta', 'episode_kind']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="ingest source" name={['odk_meta', 'ingest_source']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="program id" name={['odk_meta', 'program_id']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="is kocowa" name={['odk_meta', 'is_kocowa']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="on air date" name={['odk_meta', 'on_air_date']}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  label="episode publish start"
                  name={['odk_meta', 'episode_publish_start']}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="episode publish end" name={['odk_meta', 'episode_publish_end']}>
                  <Input readOnly />
                </Form.Item>
              </>
            ) : null}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
