import { Payload } from 'recharts/types/component/DefaultLegendContent';

type RevenueBarChartHandlerProps = {
  selectedMonth: string | null;
  selectedLegend: string | null;
  setSelectedMonth: (month: string | null) => void;
  setSelectedLegend: (legend: string | null) => void;
};
export const getRevenueBarChartHandler = ({
  selectedMonth,
  selectedLegend,
  setSelectedMonth,
  setSelectedLegend,
}: RevenueBarChartHandlerProps): {
  handleLegendClick: (data: Payload) => void;
  handleBarClick: (yearmon: string) => void;
} => {
  const handleLegendClick = (data: Payload): void => {
    if (selectedLegend === data.dataKey) {
      setSelectedLegend(null);
    } else {
      setSelectedLegend(data.dataKey as string);
    }
  };

  const handleBarClick = (yearmon: string): void => {
    // 현재 필터된 데이터가 클릭한 yearmon와 동일한 경우 (이미 선택된 경우)
    if (selectedMonth) {
      setSelectedMonth(null);
    } else {
      setSelectedMonth(yearmon);
    }
  };

  return {
    handleLegendClick,
    handleBarClick,
  };
};
