import { Form } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import * as Component from '../../../../shared/components';
import { videoSourceService } from '../service/videoSourceService';
import { VideoSourceInterface } from '../type/videoSourceType';
import * as VideoSourceComponent from './components';

const VideoSourceODK: React.FC = () => {
  const [form] = Form.useForm();
  const [videoSource, setVideoSource] = useState<VideoSourceInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchVideoSource = async (): Promise<void> => {
      const fetchedVideoSource = await videoSourceService.getODKItem();
      if (fetchedVideoSource) {
        setVideoSource(fetchedVideoSource);
        form.setFieldsValue(fetchedVideoSource);
      }
    };

    fetchVideoSource().then(() => {
      setIsLoading(false);
    });
  }, [form]);

  return (
    <div>
      <Component.BasePageComponent>
        {isLoading ? (
          <Component.LoadingSpinnerComponent />
        ) : (
          <VideoSourceComponent.VideoSourceDetailComponent
            form={form}
            showRefreshButton={true}
            isODX={true}
            handleOnClickButton={() => {}}
            videoSource={videoSource as VideoSourceInterface}
          />
        )}
      </Component.BasePageComponent>
    </div>
  );
};

export default observer(VideoSourceODK);
