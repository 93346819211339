import { message } from 'antd';
import { FormInstance } from 'antd/es/form';
import { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { accountStore } from 'store/accountStore';
interface DetailHandlersProps {
  form: FormInstance;
  navigate: NavigateFunction;
}

interface DetailHandlersReturn {
  handleUpdate: () => Promise<void>;
}

const accountHandlers = ({ form, navigate }: DetailHandlersProps): DetailHandlersReturn => {
  const handleUpdate = async (): Promise<void> => {
    const loadingMessage = message.loading('Updating...', 0);
    const account = form.getFieldsValue();
    const oldPassword = form.getFieldValue('old_password');
    const newPassword = form.getFieldValue('new_password');
    await accountStore.update(account);
    if (oldPassword || newPassword) {
      try {
        await accountStore.updatePassword(oldPassword, newPassword);
        loadingMessage();
        navigate('/');
      } catch (error) {
        loadingMessage();
        if (error instanceof AxiosError && error.response?.status === 400) {
          const errorData = error.response.data;
          // 각 필드별로 에러 상태를 직접 설정
          if (errorData.old_password) {
            form.setFields([
              {
                name: 'old_password',
                errors: errorData.old_password,
              },
            ]);
          }
          if (errorData.new_password) {
            form.setFields([
              {
                name: 'new_password',
                errors: errorData.new_password,
              },
            ]);
          }
        }
      }
    } else {
      loadingMessage();
      navigate('/');
    }
  };

  return { handleUpdate };
};

export default accountHandlers;
