import axios, { AxiosResponse } from 'axios';
import {
  BASE_URL,
  CONTENT_API_PATH,
  DIRECT_UPLOAD_MULTIPART_COMPLETE_API_PATH,
  DIRECT_UPLOAD_MULTIPART_PRESIGNED_URL_API_PATH,
  DIRECT_UPLOAD_MULTIPART_START_API_PATH,
} from 'constants/api';
import {
  DirectUploadCompleteInterface,
  DirectUploadCompleteResponseInterface,
  DirectUploadPreSignedUrlInterface,
  DirectUploadPreSignedUrlResponseInterface,
  DirectUploadStartResponseInterface,
  PutPreSignedUrlInterface,
  PutPreSignedUrlResponseInterface,
} from '../../type/uploadVideoType';

export const directUploadStart = async (
  name: string,
  type: string,
  size: number,
  contentProvider: string
): Promise<AxiosResponse<DirectUploadStartResponseInterface>> => {
  return await axios.post(`${BASE_URL}${DIRECT_UPLOAD_MULTIPART_START_API_PATH}`, {
    file_name: name,
    file_type: type,
    file_size: size,
    content_provider: contentProvider,
  });
};

export const directUploadComplete = async (
  directUploadCompleteProps: DirectUploadCompleteInterface
): Promise<AxiosResponse<DirectUploadCompleteResponseInterface>> => {
  const { directVideoUploadFile, contentId, uploadId, uploadedParts } = directUploadCompleteProps;
  const { title, name } = directVideoUploadFile;

  return await axios.post(`${BASE_URL}${DIRECT_UPLOAD_MULTIPART_COMPLETE_API_PATH}`, {
    // eslint-disable-next-line camelcase
    file_name: title || name,
    // eslint-disable-next-line camelcase
    content_id: contentId,
    // eslint-disable-next-line camelcase
    upload_id: uploadId,
    // eslint-disable-next-line camelcase
    parts: uploadedParts,
  });
};

export const putPreSignedUrl = async (
  putPreSignedUrlProps: PutPreSignedUrlInterface
): Promise<AxiosResponse<PutPreSignedUrlResponseInterface>> => {
  const { preSignedUrl, chunk, onChunkProgress } = putPreSignedUrlProps;
  return await axios.put(preSignedUrl, chunk, {
    headers: {
      'Content-Type': chunk.type,
    },
    onUploadProgress: (progressEvent): void => {
      onChunkProgress(progressEvent.loaded);
    },
  });
};

export const directUploadPreSignedUrl = async (
  directUploadPreSignedUrlProps: DirectUploadPreSignedUrlInterface
): Promise<AxiosResponse<DirectUploadPreSignedUrlResponseInterface>> => {
  const { directVideoUploadFile, contentId, uploadId, partNumber } = directUploadPreSignedUrlProps;
  const { title, name } = directVideoUploadFile;
  return await axios.post(`${BASE_URL}${DIRECT_UPLOAD_MULTIPART_PRESIGNED_URL_API_PATH}`, {
    // eslint-disable-next-line camelcase
    file_name: title || name,
    // eslint-disable-next-line camelcase
    content_id: contentId,
    // eslint-disable-next-line camelcase
    upload_id: uploadId,
    // eslint-disable-next-line camelcase
    part_number: partNumber,
  });
};

export const videoTranscode = async (contentId: number): Promise<void> => {
  await axios.post(`${BASE_URL}${CONTENT_API_PATH}${contentId}/video-transcode/`);
};
