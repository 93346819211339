import { FormInstance, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import axios from 'axios';
import { THUMBNAIL_UPLOAD_STATE } from 'constants/index';
import { seasonService } from 'content/service';
import { PosterInterface, UploadPosterInterface } from 'content/type';

interface PosterHandlersProps {
  form: FormInstance;
  seasonId: number;
  selectedPoster: PosterInterface | null;
  setPosterList: (posterList: PosterInterface[]) => void;
  setSelectedPoster: (poster: PosterInterface | null) => void;
}

interface PosterHandlers {
  handleChange: (info: UploadChangeParam<UploadFile<UploadPosterInterface>>) => void;
  handlePosterRemove: (poster: PosterInterface) => void;
  handlePosterSelect: (poster: PosterInterface | null) => void;
  handlePosterUpload: () => void;
}

const createUploadPosterList = (
  uploadFileList: UploadFile<UploadPosterInterface>[]
): PosterInterface[] => {
  let fileId = 0;
  return uploadFileList.map((file: UploadFile<UploadPosterInterface>): PosterInterface => {
    const fileUrl = file.originFileObj ? URL.createObjectURL(file.originFileObj) : '';
    return {
      id: fileId++,
      file: fileUrl,
      is_representative: false,
      state: THUMBNAIL_UPLOAD_STATE.UPLOADING,
      originFileObj: file.originFileObj,
    };
  });
};

const updateFormValues = (
  form: FormInstance,
  posterList: PosterInterface[],
  uploadPosterList: PosterInterface[]
): void => {
  form.setFieldValue('posterList', posterList);
  form.setFieldValue(
    'uploadPosterList',
    uploadPosterList.map(poster => ({
      id: poster.id,
      file: poster.originFileObj,
    }))
  );
};

const handleChange =
  (props: PosterHandlersProps) =>
  (info: UploadChangeParam<UploadFile<UploadPosterInterface>>): void => {
    const { form, setPosterList } = props;
    const uploadFileList = info.fileList || [];
    const posterList = form.getFieldValue('posterList') || [];
    const uploadPosterList = createUploadPosterList(uploadFileList);

    const newPosterList = [
      ...uploadPosterList,
      ...posterList.filter(
        (poster: { state: string }) => poster.state === THUMBNAIL_UPLOAD_STATE.UPLOADED
      ),
    ];

    updateFormValues(form, newPosterList, uploadPosterList);
    setPosterList(newPosterList);
  };

const handlePosterRemove =
  (props: PosterHandlersProps) =>
  (removedPoster: PosterInterface): void => {
    const { form, setPosterList } = props;
    const posterList = form.getFieldValue('posterList') || [];
    const uploadPosterList = form.getFieldValue('uploadPosterList') || [];

    const newPosterList = posterList.filter(
      (poster: { id: number }) => poster.id !== removedPoster.id
    );
    const newUploadPosterList = uploadPosterList.filter(
      (poster: { id: number }) => poster.id !== removedPoster.id
    );

    form.setFieldValue('posterList', newPosterList);
    form.setFieldValue('uploadPosterList', newUploadPosterList);

    setPosterList(newPosterList);
  };

const handlePosterSelect =
  (props: PosterHandlersProps) =>
  (newSelectedPoster: PosterInterface | null): void => {
    const { form, selectedPoster, setSelectedPoster } = props;
    if (selectedPoster) {
      selectedPoster.is_representative = false;
    }
    if (newSelectedPoster) {
      newSelectedPoster.is_representative = true;
    }
    form.setFieldValue('selectedPoster', newSelectedPoster);
    setSelectedPoster(newSelectedPoster);
  };

const handlePosterUpload = (props: PosterHandlersProps) => async (): Promise<void> => {
  const { form, seasonId } = props;
  const posterList = form.getFieldValue('posterList');
  const selectedPoster = form.getFieldValue('selectedPoster');
  const uploadPosterList = form.getFieldValue('uploadPosterList');
  const uploadedPosterList = form.getFieldValue('uploadedPosterList');

  if (selectedPoster) {
    uploadPosterList.map((file: { id: number; is_representative: boolean }) => {
      if (file.id === selectedPoster.id) {
        file.is_representative = selectedPoster.is_representative;
        selectedPoster.id = -1;
      }
      return file;
    });
  }

  const removePosters = uploadedPosterList.filter(
    (uploadedPoster: { id: number }) =>
      !posterList.some((uploadPoster: { id: number }) => uploadPoster.id === uploadedPoster.id)
  );

  try {
    if (uploadPosterList.length > 0) {
      await seasonService.uploadPoster(seasonId, uploadPosterList);
    }
    if (removePosters.length > 0) {
      await seasonService.removePoster(seasonId, removePosters);
    }
    if (selectedPoster && selectedPoster.id !== -1) {
      await seasonService.updatePoster(seasonId, selectedPoster);
    }
    message.success('upload success');
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      message.error('Failed to update : ' + JSON.stringify(error.response.data));
    }
  }
};

const posterHandlers = (props: PosterHandlersProps): PosterHandlers => ({
  handleChange: handleChange(props),
  handlePosterRemove: handlePosterRemove(props),
  handlePosterSelect: handlePosterSelect(props),
  handlePosterUpload: handlePosterUpload(props),
});

export default posterHandlers;
