import axios from 'axios';
import { BASE_URL, LICENSEE_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { LicenseeInterface } from '../type/licenseeType';

export class LicenseeService {
  constructor(private apiService: ApiService<LicenseeInterface>) {}
  public async fetchLicensee(): Promise<LicenseeInterface[]> {
    const queryParams = {
      page_size: '100',
      type: 'pp',
    };
    const result = await this.apiService.fetch(queryParams);
    return result.results;
  }

  public async fetchById(id: number): Promise<LicenseeInterface> {
    const response = await axios.get(`${BASE_URL}${LICENSEE_API_PATH}${id}/`);
    return response.data;
  }
}

export const licenseeService = new LicenseeService(new ApiService(LICENSEE_API_PATH));
