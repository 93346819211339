export const UPLOAD_CHUNK_SIZE = 100 * 1024 * 1024; // 100MB

export const CONTENT_TYPE = ['movie', 'tv_show', 'shortform'];
export const AGE_RATING = ['ALL', 'PG', 'PG-13', 'R', 'NC-17'];
export const STATUS = [
  'draft',
  'pending',
  'published',
  'archived',
  'suspended',
  'deleting',
  'scheduled',
  'expired',
];

export const LANGUAGE_LIST = ['ko', 'es', 'en', 'cn', 'jp'];
export const SUBTITLE_FILE_EXTENSION = ['.srt', '.vtt', '.smi'];
export const DUBBING_FILE_EXTENSION = ['.wav', '.aac', '.mp3'];

export const METADATA_SUB_PATHS = ['category', 'people', 'series', 'season'];

export const THUMBNAIL_UPLOAD_STATE = {
  UPLOADED: 'Uploaded',
  UPLOADING: 'Uploading',
};

export const CONTENT_RULE_TYPE = ['content', 'season', 'series'];
