import { PLAYLIST_API_PATH } from 'constants/api';
import { ApiService } from 'service/apiService';
import { FetchResult } from 'type/apiType';
import { PlaylistInterface } from '../type/playlistType';

class PlaylistService {
  constructor(private apiService: ApiService<PlaylistInterface>) {}

  public async fetch(params?: Record<string, string>): Promise<FetchResult<PlaylistInterface>> {
    return this.apiService.fetch(params);
  }

  public async fetchById(id: number, params?: Record<string, string>): Promise<PlaylistInterface> {
    return this.apiService.fetchById(id, params);
  }

  public async create(playlist: PlaylistInterface): Promise<PlaylistInterface> {
    return await this.apiService.create(playlist);
  }

  public async update(id: number, playlist: PlaylistInterface): Promise<void> {
    await this.apiService.update(id, playlist);
  }
}

export const playlistService = new PlaylistService(
  new ApiService<PlaylistInterface>(PLAYLIST_API_PATH)
);
