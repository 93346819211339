import axios from 'axios';
import { BASE_URL, LOGIN_API_PATH, LOGOUT_API_PATH, REFRESH_API_PATH } from 'constants/api';
import { accountStore } from 'store/accountStore';

interface AuthServiceInterface {
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: () => Promise<boolean>;
  refreshToken: () => Promise<{ access: string }>;
  clearAuthInfo: () => Promise<void>;
}

const authService: AuthServiceInterface = {
  login: async (username: string, password: string) => {
    const data = {
      username,
      password,
    };
    const response = await axios.post(`${BASE_URL}${LOGIN_API_PATH}`, data);
    if (response.status === 200) {
      const authToken = response.data['access'];
      localStorage.setItem('authToken', authToken);
      await accountStore.init();
    }
  },

  logout: async () => {
    await axios.post(`${BASE_URL}${LOGOUT_API_PATH}`);
    await authService.clearAuthInfo();
    window.location.href = '/login'; // 로그인 페이지로 리다이렉트
  },

  isAuthenticated: async (): Promise<boolean> => {
    const authToken = localStorage.getItem('authToken');
    const isInit = accountStore.isInit();
    if (!authToken || !isInit) {
      try {
        await accountStore.init();
        return true;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        return false;
      }
    }
    return true;
  },

  refreshToken: async (): Promise<{ access: string }> => {
    const response = await axios.post(`${BASE_URL}${REFRESH_API_PATH}`);
    return response.data;
  },

  clearAuthInfo: async (): Promise<void> => {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c.trim().split('=')[0] + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    });
    accountStore.reset();
    if ('caches' in window) {
      await caches.keys().then(names => {
        names.forEach(name => caches.delete(name));
      });
    }
  },
};

export default authService;
