import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import { dubbingHandlers } from 'content/page/content/handlers';
import { contentService } from 'content/service/contentService';
import { DubbingInterface } from 'content/type';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDubbingColumns } from './DubbingColumns';

export const Dubbing: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState<DubbingInterface[]>([]);
  const [form] = Form.useForm();

  const { handleLanguageChange, handleFileChange, handleAddDubbing, handleUpdateDubbing } =
    dubbingHandlers({
      form,
      contentId: Number(id),
      setDataSource,
    });

  const columns = getDubbingColumns({
    handleLanguageChange,
    handleFileChange,
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const dubbingList = await contentService.fetchDubbingList(Number(id));
      setDataSource(dubbingList.results);
    };
    fetchData();
  }, [id]);

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey={record => record.id.toString()}
      />
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        style={{ width: '100%', marginTop: '10px' }}
        onClick={() => handleAddDubbing(dataSource.length + 1)}
      >
        New Dubbing
      </Button>
      <Form.Item style={{ marginTop: 16, textAlign: 'center' }}>
        <Button type="primary" onClick={() => handleUpdateDubbing()}>
          Save
        </Button>
      </Form.Item>
    </>
  );
};

export default Dubbing;
